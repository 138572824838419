import React, {MouseEventHandler} from "react"
import { Navbar } from "react-bootstrap"
import logo from "../logo.svg"
import logoSmall from "../logo-small.svg"

interface BrandProps {
  className?: string
  onClick?: MouseEventHandler<HTMLElement>
  href?: string
}

function Brand({ href, onClick, className }: BrandProps) {
  return (
    <div>
      <Navbar.Brand href={href} onClick={onClick} className={className} role="button">
        <img src={logo} height="26" alt="Logo" />
      </Navbar.Brand>
    </div>
  )
}

export default Brand
