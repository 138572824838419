import React, {FormEvent, useState} from "react"
import { Modal } from "react-bootstrap"
import Alert from "../alert"

interface VerifyEmailModalProps {
  show: boolean
  isBusy: boolean
  onHide: () => void
  onSubmit: (code: string) => void
  email: string
}

function VerifyEmailModal({email, show, isBusy, onHide, onSubmit}: VerifyEmailModalProps) {
  const [code, setCode] = useState('')

  const handleRequestNewCode = () => {}

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit(code)
  }

  return <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>We need to verify your email</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Alert />
      <form className="row justify-content-center" onSubmit={handleSubmit}>
      <p className="text-center mb-4">
        A verification code has been sent to{" "}
        <strong>{email}</strong>
        <br />
        Please check the spam folder if you can't find the code.
      </p>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="form-floating mb-1">
            <input
              type="number"
              className="form-control"
              id="code"
              placeholder="code"
              onChange={(e) => setCode(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="code" className="text-muted">
              Code
            </label>
          </div>
          <div className="text-end mall mb-3">
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={() => handleRequestNewCode()}
            >
              Request a new code
            </button>
          </div>
          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-primary w-100 btn-lg"
              disabled={isBusy ? true : undefined}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
      </form>
    </Modal.Body>
  </Modal>
}

export default VerifyEmailModal
