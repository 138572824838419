import React, {useEffect} from "react"
import {useUserPool} from "./user-context"
import {DomainService} from "../services/domain-service"
import {Domain, DomainColumns, DomainGrouping, DomainSchema, DomainSort} from "../types"
import {track} from "../services/analytics"

interface State {
  domains: Domain[]
  isLoading: boolean
  columns: DomainColumns
  grouping: DomainGrouping
  sort: DomainSort
}

interface Context {
  state: State
  addDomain: (name: string) => void
  deleteDomain: (name: string) => void
  updateDomain: (string: string) => void
  attachLabel: (domain: string, label: string) => void
  removeLabel: (domain: string, label: string) => void
  removeLabels: (label: string) => void
  updateColumns: (columns: DomainColumns) => void
  updateGrouping: (grouping: DomainGrouping) => void
  updateSort: (sort: DomainSort) => void
}

const DomainContext = React.createContext<Context>({} as Context)

type DomainProviderProps = { children: React.ReactNode }

type Action =
  | { type: "added"; payload: Domain }
  | { type: "loaded"; payload: Domain[] }
  | { type: "deleted"; payload: string }
  | { type: "updated"; payload: Domain }
  | { type: "labelAttached"; payload: [string, string] }
  | { type: "labelRemoved"; payload: [string, string] }
  | { type: "labelsRemoved"; payload: string }
  | { type: "columnsUpdated"; payload: DomainColumns }
  | { type: "groupingUpdated"; payload: DomainGrouping }
  | { type: "sortUpdated"; payload: DomainSort }

const defaultColumns: DomainColumns = {
  daysLeft: true,
  registrar: true,
  labels: true,
}

const initialState = {
  domains: [],
  isLoading: true,
  columns: defaultColumns,
  grouping: DomainGrouping.None,
  sort: DomainSort.Expiration,
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "added":
      const newDomains = [...state.domains, action.payload]
      if (state.sort === DomainSort.Expiration) {
        newDomains.sort((a, b) => (a.Expiry > b.Expiry ? 1 : -1))
      }
      if (state.sort === DomainSort.Alphabetical) {
        newDomains.sort((a, b) => (a.Name > b.Name ? 1 : -1))
      }
      return {...state, domains: newDomains}
    case "deleted":
      const name = action.payload
      const index = state.domains.findIndex((x) => x.Name === name)
      const x = [...state.domains]
      x.splice(index, 1)
      return {...state, domains: x}
    case "updated":
      const updatedDomains = state.domains.map((d) => {
        if (d.Name === action.payload.Name) {
          return {...d, ...action.payload}
        }
        return d
      })
      return {...state, domains: updatedDomains}
    case "columnsUpdated":
      return {...state, columns: action.payload}
    case "groupingUpdated":
      return {...state, grouping: action.payload}
    case "sortUpdated":
      const sortedDomains = state.domains
      if (action.payload === DomainSort.Expiration) {
        sortedDomains.sort((a, b) => (a.Expiry > b.Expiry ? 1 : -1))
      }
      if (action.payload === DomainSort.Alphabetical) {
        sortedDomains.sort((a, b) => (a.Name > b.Name ? 1 : -1))
      }
      return {...state, sort: action.payload, domains: sortedDomains}
    case "labelAttached":
      const ud = state.domains.map((d) => {
        if (d.Name === action.payload[0]) {
          d.Labels[action.payload[1]] = {
            Color: "#ffffff",
          }
        }
        return d
      })
      return {...state, domains: ud}
    case "labelRemoved":
      const ud2 = state.domains.map((d) => {
        if (d.Name === action.payload[0]) {
          delete d.Labels[action.payload[1]]
        }
        return d
      })
      return {...state, domains: ud2}
    case "labelsRemoved":
      const ud3 = state.domains.map((d) => {
        delete d.Labels[action.payload]
        return d
      })
      return {...state, domains: ud3}
    case "loaded":
      const domains = action.payload
      if (state.sort === DomainSort.Expiration) {
        domains.sort((a, b) => (a.Expiry > b.Expiry ? 1 : -1))
      }
      if (state.sort === DomainSort.Alphabetical) {
        domains.sort((a, b) => (a.Name > b.Name ? 1 : -1))
      }
      return {...state, isLoading: false, domains: action.payload}
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

/*
async function load(token: string) {
  const url =
    "https://e8b6nrjab7.execute-api.eu-central-1.amazonaws.com/domains"
  const response = await fetch(url, {
    mode: "cors",
    credentials: "omit",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
  return response.json()
}*/

function DomainProvider({children}: DomainProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {currentUser} = useUserPool()

  useEffect(() => {
    if (currentUser == null) {
      return
    }

    if (localStorage.getItem("DomainsColumns") !== null) {
      const columns = JSON.parse(localStorage.getItem("DomainsColumns") || "")
      dispatch({type: "columnsUpdated", payload: columns})
    }

    if (localStorage.getItem("DomainsGrouping") !== null) {
      const grouping = JSON.parse(localStorage.getItem("DomainsGrouping") || "")
      dispatch({type: "groupingUpdated", payload: grouping})
    }

    if (localStorage.getItem("DomainsSort") !== null) {
      const sort = JSON.parse(localStorage.getItem("DomainsSort") || "")
      dispatch({type: "sortUpdated", payload: sort})
    }

    DomainService.getDomains().then((domains) => {
      dispatch({type: "loaded", payload: domains})
    })
  }, [currentUser])

  const addDomain = async (name: string) => {
    const domain = await DomainService.addDomain(name)
    track("add_domain")
    dispatch({type: "added", payload: domain})
  }

  const deleteDomain = async (name: string) => {
    await DomainService.deleteDomain(name)
    track("delete_domain")
    dispatch({type: "deleted", payload: name})
  }

  const updateDomain = async (name: string) => {
    const updatedDomain = await DomainService.updateDomain(name)
    track("update_domain")
    dispatch({type: "updated", payload: updatedDomain})
  }

  const attachLabel = async (domain: string, label: string) => {
    await DomainService.attachLabel(domain, label)
    track("attach_label")
    dispatch({type: "labelAttached", payload: [domain, label]})
  }

  const removeLabel = async (domain: string, label: string) => {
    await DomainService.removeLabel(domain, label)
    track("remove_label")
    dispatch({type: "labelRemoved", payload: [domain, label]})
  }

  const removeLabels = async (label: string) => {
    dispatch({type: "labelsRemoved", payload: label})
  }

  const updateColumns = (columns: DomainColumns) => {
    localStorage.setItem("DomainsColumns", JSON.stringify(columns))
    dispatch({type: "columnsUpdated", payload: columns})
  }

  const updateGrouping = (grouping: DomainGrouping) => {
    localStorage.setItem("DomainsGrouping", JSON.stringify(grouping))
    dispatch({type: "groupingUpdated", payload: grouping})
  }

  const updateSort = (sort: DomainSort) => {
    localStorage.setItem("DomainsSort", JSON.stringify(sort))
    dispatch({type: "sortUpdated", payload: sort})
  }

  return (
    <DomainContext.Provider
      value={{
        state,
        addDomain,
        deleteDomain,
        updateDomain,
        attachLabel,
        removeLabel,
        removeLabels,
        updateColumns,
        updateGrouping,
        updateSort,
      }}
    >
      {children}
    </DomainContext.Provider>
  )
}

function useDomain() {
  const context = React.useContext(DomainContext)
  if (context === undefined) {
    throw new Error("useDomain must be used within a DomainProvider")
  }
  return context
}

export {DomainProvider, useDomain}
