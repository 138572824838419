import React, { useState } from "react"
import "./header.css"
import { useUserPool } from "../contexts/user-context"
import { useLocation, useNavigate } from "react-router-dom"
import { Container, Nav, Navbar } from "react-bootstrap"
import Brand from "./brand"

function Header() {
  const { currentUser, signOut } = useUserPool()
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  if (!currentUser.isAuthenticated) {
    return (
      <div className="mt-4">
        <div className="d-flex justify-content-center">
          <Brand href="https://domainwatchman.com" />
        </div>
      </div>
    )
  }

  const handleNavigate = (url: string) => {
    navigate(url)
    setExpanded(false)
  }

  const handleSignOut = async () => {
    await signOut()
    window.location.href = "https://domainwatchman.com"
  }

  return (
    <div className="bg-white">
      <Navbar bg="light" expand="lg" className="mb-5 pt-4" expanded={expanded}>
        <Container>
          <Brand onClick={() => navigate('/')} />
          <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse>
            <Nav className="ms-auto d-flex align-items-end">
              <Nav.Link
                onClick={() => handleNavigate("/")}
                active={location.pathname === "/"}
              >
                Domains
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavigate("/history")}
                active={location.pathname === "/history"}
              >
                History
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavigate("/settings")}
                active={location.pathname === "/settings" || location.pathname === "/change-plan"}
              >
                Settings
              </Nav.Link>
              {currentUser.groups?.includes("Admin") ? (
                <Nav.Link
                  onClick={() => handleNavigate("/admin")}
                  active={location.pathname === "/admin"}
                >
                  Admin
                </Nav.Link>
              ) : null}
              <Nav.Link onClick={handleSignOut}>Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
