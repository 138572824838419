import React, {useEffect, useState} from "react"
import {useLocation} from "react-router-dom";

interface Context {
  text: string,
  type: string,
  dismissible: boolean,
  error: (text: string, dismissible?: boolean) => void
  success: (text: string, dismissible?: boolean) => void
  clear: () => void
}

const AlertContext = React.createContext<Context>({} as Context)
type ContextProviderProps = { children: React.ReactNode }

function AlertProvider({children}: ContextProviderProps) {
  const [text, setText] = useState("")
  const [type, setType] = useState("")
  const [dismissible, setDismissible] = useState(true)
  const location = useLocation()

  useEffect(() => {
    clear()
  }, [location])

  const clear = () => {
    setText("")
  }

  const error = (text: string, dismissible: boolean = true, timeout: number = 0) => {
    setText(text)
    setType("danger")
    setDismissible(dismissible)
    if (timeout > 0) {
      setTimeout(() => {
        setText("")
      }, timeout * 1000)
    }
  }
  const success = (text: string, dismissible: boolean = true, timeout: number = 0) => {
    setText(text)
    setType("success")
    setDismissible(dismissible)
    if (timeout > 0) {
      setTimeout(() => {
        setText("")
      }, timeout * 1000)
    }
  }

  return <AlertContext.Provider value={{error, success, text, type, dismissible, clear}}>{children}</AlertContext.Provider>
}

function useAlert() {
  const context = React.useContext(AlertContext)
  if (context === undefined) {
    throw new Error("useDomain must be used within a DomainProvider")
  }
  return context
}

export {AlertProvider, useAlert}
