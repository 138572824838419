import React from "react"

function Placeholder() {
  return (
    <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6">
      <h5 className="card-title placeholder-glow">
        <span className="placeholder col-6" />
      </h5>
      <p className="card-text placeholder-glow">
        <span className="placeholder col-7 me-1" />
        <span className="placeholder col-4" />
        <span className="placeholder col-4 me-1" />
        <span className="placeholder col-6 me-1" />
        <span className="placeholder col-8 me-1" />
      </p>
    </div>
  )
}

export default Placeholder
