import React, { useState } from "react"
import { Dropdown, Modal } from "react-bootstrap"
import { Domain, Labels, Whois } from "../../types"
import { useDomain } from "../../contexts/domain-context"
import "./domain-item.css"
import { useAlert } from "../../contexts/alert-context"
import LabelAsModal from "./label-as-modal"
import { CSSTransition } from "react-transition-group"

interface DomainItemProps {
  domain: Domain
}

const getStatus = function (domain: Domain): string {
  const now = new Date()
  if (domain.Expiry > now) {
    return (
      Math.round(
        Math.abs((domain.Expiry.getTime() - now.getTime()) / 86400000)
      ) + " days"
    )
  }
  if (domain.Status?.includes("registered")) {
    return "unknown"
  }

  if (domain.Status?.includes("pendingdelete")) {
    return "pending delete"
  }

  return domain.Registrar !== "" ? "unknown" : "available"
}

function DomainItem({ domain }: DomainItemProps) {
  const alert = useAlert()
  const [isBusy, setBusy] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showLabelAs, setShowLabelAs] = useState(false)
  const { deleteDomain, updateDomain, state } = useDomain()

  async function handleUpdate() {
    setBusy(true)
    alert.clear()

    try {
      await updateDomain(domain.Name)
    } catch (err) {
      if (err instanceof Error) {
        alert.error(err.message)
      }
    }

    setBusy(false)
  }

  function handleShowDetails() {
    setShowDetails(true)
  }

  function handleShowLabelAs() {
    setShowLabelAs(true)
  }

  async function handleDelete() {
    setBusy(true)
    await deleteDomain(domain.Name)
    setBusy(false)
  }

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
      <div className="card h-100">
        <div className="card-body text-start">
          <div className="d-flex justify-content-between align-items-center card-title mb-0">
            <h6 className="mb-0 text-truncate text-start fw-bold">
              {domain.Name}
            </h6>
            {isBusy ? (
              <span
                className="spinner-border spinner-border-sm text-secondary"
                role="status"
              />
            ) : (
              <Dropdown>
                <Dropdown.Toggle variant="vertical-ellipsis">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="ellipsis-v"
                    className=""
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 512"
                    height="24"
                    width="4"
                  >
                    <path
                      fill="#777"
                      d="M32 224c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zM0 136c0 17.7 14.3 32 32 32s32-14.3 32-32-14.3-32-32-32-32 14.3-32 32zm0 240c0 17.7 14.3 32 32 32s32-14.3 32-32-14.3-32-32-32-32 14.3-32 32z"
                    />
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleShowDetails}>
                    Details
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleShowLabelAs}>
                    Label as...
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleUpdate}>
                    Update Now
                    <br />
                    <span className="text-black small text-opacity-50">
                      Last updated:
                      <br /> {domain.RecordUpdated.toLocaleString()}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div>
            {state.columns.daysLeft ? (
              <div className="small d-flex align-items-center mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  height="14"
                  className="fw-icon"
                  fill="currentColor"
                >
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" />
                </svg>
                <div className="ms-1 text-truncate">{getStatus(domain)}</div>
              </div>
            ) : null}
            {state.columns.registrar && domain.Registrar !== "" ? (
              <div className="small d-flex align-items-center mt-2">
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height="14"
                    fill="currentColor"
                    className="fw-icon"
                  >
                    <path d="M376 256c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm-40 24c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24zm176-128c0 12.296-4.629 23.507-12.232 32 7.603 8.493 12.232 19.704 12.232 32v80c0 12.296-4.629 23.507-12.232 32 7.603 8.493 12.232 19.704 12.232 32v80c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-80c0-12.296 4.629-23.507 12.232-32C4.629 319.507 0 308.296 0 296v-80c0-12.296 4.629-23.507 12.232-32C4.629 175.507 0 164.296 0 152V72c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v80zm-480 0c0 8.822 7.178 16 16 16h416c8.822 0 16-7.178 16-16V72c0-8.822-7.178-16-16-16H48c-8.822 0-16 7.178-16 16v80zm432 48H48c-8.822 0-16 7.178-16 16v80c0 8.822 7.178 16 16 16h416c8.822 0 16-7.178 16-16v-80c0-8.822-7.178-16-16-16zm16 160c0-8.822-7.178-16-16-16H48c-8.822 0-16 7.178-16 16v80c0 8.822 7.178 16 16 16h416c8.822 0 16-7.178 16-16v-80zm-80-224c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24zm-64 0c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24zm64 240c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24z" />
                  </svg>
                </div>
                <div className="ms-1 text-truncate">{domain.Registrar}</div>
              </div>
            ) : null}
            {state.columns.labels && Object.keys(domain.Labels).length > 0 ? (
              <div className="small d-flex align-items-center mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  height="14"
                  fill="currentColor"
                  className="fw-icon"
                >
                  <path d="M625.941 293.823L421.823 497.941c-18.746 18.746-49.138 18.745-67.882 0l-1.775-1.775 22.627-22.627 1.775 1.775c6.253 6.253 16.384 6.243 22.627 0l204.118-204.118c6.238-6.239 6.238-16.389 0-22.627L391.431 36.686A15.895 15.895 0 0 0 380.117 32h-19.549l-32-32h51.549a48 48 0 0 1 33.941 14.059L625.94 225.941c18.746 18.745 18.746 49.137.001 67.882zM252.118 32H48c-8.822 0-16 7.178-16 16v204.118c0 4.274 1.664 8.292 4.686 11.314l211.882 211.882c6.253 6.253 16.384 6.243 22.627 0l204.118-204.118c6.238-6.239 6.238-16.389 0-22.627L263.431 36.686A15.895 15.895 0 0 0 252.118 32m0-32a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.746 18.746-49.138 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118V48C0 21.49 21.49 0 48 0h204.118zM144 124c-11.028 0-20 8.972-20 20s8.972 20 20 20 20-8.972 20-20-8.972-20-20-20m0-28c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.49-48 48-48z" />
                </svg>
                <div className="ms-1 text-truncate">
                  {Object.keys(domain.Labels).join(", ")}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <LabelAsModal
        show={showLabelAs}
        onHide={() => setShowLabelAs(false)}
        domain={domain}
      />
      <Modal
        show={showDetails}
        onHide={() => setShowDetails(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{domain.Name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border p-3">
            <span className="small opacity-50">Status</span>
            <span className="ms-2">
              {domain.Status === null ? "available" : domain.Status.join(", ")}
            </span>
          </div>
          <div className="row">
            {domain.Created.getFullYear() > 1 ? (
              <div className="col-12 col-md-4 mt-4">
                <div className="border rounded p-3">
                  <div className="small opacity-50 mb-2">Created</div>
                  <div>{domain.Created.toDateString()}</div>
                  <div>{domain.Created.toTimeString()}</div>
                </div>
              </div>
            ) : null}
            {domain.Updated.getFullYear() > 1 ? (
              <div className="col-12 col-md-4 mt-4">
                <div className="border rounded p-3">
                  <div className="small opacity-50 mb-2">Updated</div>
                  <div>{domain.Updated.toDateString()}</div>
                  <div>{domain.Updated.toTimeString()}</div>
                </div>
              </div>
            ) : null}
            {domain.Expiry.getFullYear() > 1 ? (
              <div className="col-12 col-md-4 mt-4">
                <div className="border rounded p-3">
                  <div className="small opacity-50 mb-2">Expiry</div>
                  <div>{domain.Expiry.toDateString()}</div>
                  <div>{domain.Expiry.toTimeString()}</div>
                </div>
              </div>
            ) : null}
          </div>
          {domain.Registrar ? (
            <div className="mt-4 border p-3">
              <span className="small opacity-50">Registrar</span>
              <span className="ms-2">
                <a href={domain.RegistrarUrl} target="_blank" rel="nofollow">
                  {domain.Registrar}
                </a>
              </span>
            </div>
          ) : null}
          {domain.Registrant ? (
            <div className="mt-4 border p-3 gy-5">
              <div className="small opacity-50 mb-3">Registrant</div>
              <table className="table small mb-0">
                <tbody>
                  <tr>
                    <td className="text-end">Name</td>
                    <td className="w-100">{domain.Registrant.Name}</td>
                  </tr>
                  <tr>
                    <td className="text-end">Organization</td>
                    <td>{domain.Registrant.Organization}</td>
                  </tr>
                  <tr>
                    <td className="text-end">Email</td>
                    <td>{domain.Registrant.Email}</td>
                  </tr>
                  <tr>
                    <td className="border-0 pb-0 text-end">Country</td>
                    <td className="border-0 pb-0">
                      {domain.Registrant.Country}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
          {domain.Nameservers !== null ? (
            <div className="mt-4 border p-3">
              <span className="small opacity-50">Nameservers</span>
              <span className="ms-2">{domain.Nameservers.join(", ")}</span>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DomainItem
