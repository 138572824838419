import React, { FormEvent, useState } from "react"
import { useAlert } from "../contexts/alert-context"
import { useUserPool } from "../contexts/user-context"
import { Link } from "react-router-dom"
import Alert from "../components/alert"
import CenteredPage from "../components/centered-page"

interface EmailFormProps {
  isBusy: boolean
  onSubmit: (email: string) => Promise<void>
}

function EmailForm({ isBusy, onSubmit }: EmailFormProps) {
  const [email, setEmail] = useState<string>("")
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await onSubmit(email)
  }
  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="form-floating mb-3">
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="email"
          onChange={(e) => setEmail(e.target.value)}
          disabled={isBusy ? true : undefined}
          required
        />
        <label htmlFor="email" className="text-muted">
          Email
        </label>
      </div>
      <div className="mb-3">
        <button
          className="btn btn-primary w-100 btn-lg"
          disabled={isBusy ? true : undefined}
        >
          Reset Password
        </button>
      </div>
    </form>
  )
}

interface PasswordFormProps {
  isBusy: boolean
  onSubmit: (code: string, newPassword: string) => Promise<void>
}

function PasswordForm({ isBusy, onSubmit }: PasswordFormProps) {
  const [code, setCode] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await onSubmit(code, newPassword)
  }

  return (
    <form onSubmit={handleSubmit}>
      <p>
        We've sent a verification code to your email. <br />
        Enter the verification code and a new password in the fields below.
      </p>
      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          id="code"
          placeholder="code"
          onChange={(e) => setCode(e.target.value)}
          disabled={isBusy ? true : undefined}
          required
        />
        <label htmlFor="code" className="text-muted">
          Verification Code
        </label>
      </div>
      <div className="form-floating mb-3">
        <input
          type="password"
          className="form-control"
          id="password"
          placeholder="password"
          onChange={(e) => setNewPassword(e.target.value)}
          disabled={isBusy ? true : undefined}
          required
        />
        <label htmlFor="password" className="text-muted">
          New Password
        </label>
      </div>
      <div className="mb-3">
        <button
          className="btn btn-primary w-100 btn-lg"
          disabled={isBusy ? true : undefined}
        >
          Change Password
        </button>
      </div>
    </form>
  )
}

function PasswordChanged() {
  return (
    <p>
      Account password has been changed successfully. You can now log in into
      your account.
    </p>
  )
}

function ForgotPassword() {
  const alert = useAlert()
  const [email, setEmail] = useState<string>("")
  const [isCodeSent, setCodeSent] = useState<boolean>(false)
  const [isPasswordChanged, setPasswordChanged] = useState<boolean>(false)
  const [isBusy, setBusy] = useState(false)
  const userPool = useUserPool()

  const handleEmailSubmit = async (email: string) => {
    alert.clear()
    setBusy(true)

    const error = await userPool.forgotPassword(email)
    if (error != null) {
      alert.error(error.message)
      setBusy(false)
      return
    }

    setCodeSent(true)
    setEmail(email)
    setBusy(false)
  }

  const handlePasswordSubmit = async (code: string, newPassword: string) => {
    alert.clear()
    setBusy(true)

    const error = await userPool.changePasswordWithCode(
      email,
      code,
      newPassword
    )
    if (error != null) {
      alert.error(error.message)
      setBusy(false)
      return
    }

    setPasswordChanged(true)
    setBusy(false)
  }

  return (
    <CenteredPage>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-7 col-md-5 col-xl-4">
          <h1 className="h3 mb-4 text-center">Reset Password</h1>
          <Alert />

          {!isCodeSent && !isPasswordChanged && (
            <EmailForm isBusy={isBusy} onSubmit={handleEmailSubmit} />
          )}

          {isCodeSent && !isPasswordChanged && (
            <PasswordForm isBusy={isBusy} onSubmit={handlePasswordSubmit} />
          )}

          {isCodeSent && isPasswordChanged && <PasswordChanged />}

          <div className="text-center">
            <Link to="/login">Go Back</Link>
          </div>
        </div>
      </div>
    </CenteredPage>
  )
}

export default ForgotPassword
