import axios from "axios"
import { Auth } from "aws-amplify"
import dayjs from "dayjs"

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

function handleDates(data: any) {
  if (typeof data !== "object" || data === null) {
    return data
  }

  for (const key of Object.keys(data)) {
    const value = data[key]
    if (
      typeof value === "string" &&
      // /^[0-9]\d{3}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(value)
      ///^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/.test(value)
      dayjs(value).isValid()
    ) {
      data[key] = new Date(value)
    } else if (typeof value === "object") {
      handleDates(value)
    }
  }
}

client.interceptors.response.use((originalResponse) => {
  // handleDates(originalResponse.data)
  return originalResponse
})

client.interceptors.request.use(
  async (config) => {
    if (typeof config.headers !== "undefined") {
      try {
        const token = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()
        config.headers["Authorization"] = `Bearer ${token}`
      } catch (e) {}
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

let authToken: string | null = null

function setAxiosAuthToken(token: string | null) {
  authToken = token
}

export { client, setAxiosAuthToken }
