import React, { useEffect, useState } from "react"
import { Amplify, Auth } from "aws-amplify"
import { DynamoDBClient, ListTablesCommand } from "@aws-sdk/client-dynamodb"
import { CognitoUser } from "amazon-cognito-identity-js"
import LogRocket from "logrocket"
import {track} from "../services/analytics";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || "",
    identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION || "",
    userPoolId: process.env.REACT_APP_USER_POOL_ID || "",
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || "",
    /*
    cookieStorage: {
      domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN || "localhost",
      path: "/",
      expires: 365,
      secure: (process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN || "") !== "",
    },*/
  },
  /*
API: {
  endpoints: [
    {
      name: "main",
      endpoint: process.env.REACT_APP_API_URL,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        }
      },
    },
  ],
},*/
})

type User = {
  username: string
  email: string
  groups: string[]
  isLoaded: boolean
  isAuthenticated: boolean
}

interface Context {
  createUser: (email: string, password: string) => Promise<Error | null>
  verifyUser: (email: string, code: string) => Promise<Error | null>
  resendVerificationCode: (email: string) => Promise<Error | null>
  forgotPassword: (email: string) => Promise<Error | null>
  changePasswordWithCode: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<Error | null>
  changePassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<Error | null>
  changeEmail: (email: string) => Promise<Error | null>
  verifyEmailChange: (code: string) => Promise<Error | null>
  authenticateUser: (email: string, password: string) => Promise<Error | null>
  signOut: () => Promise<void>
  currentUser: User
  isLoaded: boolean
}

interface Props {
  children: React.ReactNode
}

const UserPoolContext = React.createContext<Context>({} as Context)

async function listTables(cr: any) {
  const client = new DynamoDBClient({
    region: "eu-central-1",
    credentials: Auth.essentialCredentials(cr),
  })
  const command = new ListTablesCommand({})
  const response = await client.send(command)
}

function UserPoolProvider({ children }: Props) {
  const [user, setUser] = useState<User>({isLoaded: false, isAuthenticated: false, username: '', email: '', groups: [] })
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((u) => {
        setUser({
          isLoaded: true,
          isAuthenticated: true,
          username: u.username,
          email: u.attributes.email,
          groups:
            u.signInUserSession.accessToken.payload["cognito:groups"] ?? [],
        })
        setLoaded(true)

        if (localStorage.getItem("logrocket_ignore") === null) {
          LogRocket.identify(u.username, {
            email: u.attributes.email,
          })
        }
      })
      .catch((e) => {
        setUser({
          isLoaded: true,
          isAuthenticated: false,
          username: '',
          email: '',
          groups: [],
        })
      })
  }, [])

  const createUser = async (email: string, password: string) => {
    track("sign_up")
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {},
        autoSignIn: {
          enabled: false,
        },
      })
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const verifyUser = async (email: string, code: string) => {
    track("verify_email")
    try {
      await Auth.confirmSignUp(email, code)
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const resendVerificationCode = async (email: string) => {
    track("resend_verification_code")
    try {
      await Auth.resendSignUp(email)
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const authenticateUser = async (email: string, password: string) => {
    track("login")
    try {
      const user = await Auth.signIn(email, password)
      setUser({
        username: user.username,
        email: user.attributes.email,
        isAuthenticated: true,
        isLoaded: true,
        groups:
          user.signInUserSession.accessToken.payload["cognito:groups"] ?? [],
      })
      setLoaded(true)
      if (localStorage.getItem("logrocket_ignore") === null) {
        LogRocket.identify(user.username, {
          email: user.attributes.email,
        })
      }
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const forgotPassword = async (email: string): Promise<Error | null> => {
    track("send_password_reset")
    try {
      await Auth.forgotPassword(email)
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const changePasswordWithCode = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<Error | null> => {
    track("change_password_with_code")
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword)
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const changePassword = async (oldPassword: string, newPassword: string) => {
    track("change_password")
    if (user === null) {
      return new Error("Sign in into your account first")
    }
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const changeEmail = async (email: string): Promise<Error | null> => {
    track("send_change_email")
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {"email": email})
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const verifyEmailChange = async (code: string): Promise<Error | null> => {
    try {
      track("verify_email_change")
      const user = await Auth.currentAuthenticatedUser()
      await Auth.verifyUserAttributeSubmit(user, "email", code)
    } catch (e) {
      if (e instanceof Error) {
        return e
      }
    }
    return null
  }

  const signOut = async (): Promise<void> => {
    track("logout")
    await Auth.signOut()
    setUser({isLoaded: false, isAuthenticated: false, username: '', email: '', groups: []})
  }

  return (
    <UserPoolContext.Provider
      value={{
        createUser,
        verifyUser,
        resendVerificationCode,
        authenticateUser,
        signOut,
        forgotPassword,
        changePasswordWithCode,
        changePassword,
        changeEmail,
        verifyEmailChange,
        currentUser: user,
        isLoaded,
      }}
    >
      {children}
    </UserPoolContext.Provider>
  )
}

function useUserPool() {
  const context = React.useContext(UserPoolContext)
  if (context === undefined) {
    throw new Error("userUserPool must be used within a UserPoolProvider")
  }
  return context
}

export { UserPoolProvider, useUserPool }
