import React from "react"
import Page from "../components/page"
import UserList from "../components/admin/user-list"

function AdminPage() {
  return (
    <Page>
      <UserList />
    </Page>
  )
}

export default AdminPage
