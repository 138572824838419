import React, { FormEvent, useState } from "react"
import { Modal } from "react-bootstrap"
import Alert from "../alert"

interface ChangePasswordModalProps {
  show: boolean
  isBusy: boolean
  onHide: () => void
  onSubmit: (oldPassword: string, newPassword: string) => void
}

function ChangePasswordModal({
  isBusy,
  show,
  onHide,
  onSubmit,
}: ChangePasswordModalProps) {
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    onSubmit(oldPassword, newPassword)
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert />
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="oldPassword"
              placeholder="password"
              onChange={(e) => setOldPassword(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="oldPassword" className="text-muted">
              Current password
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="newPassword"
              placeholder="password"
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="newPassword" className="text-muted">
              New password
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-primary btn-lg">
              Change
            </button>
            <button
              type="button"
              className="btn btn-light btn-lg"
              onClick={onHide}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ChangePasswordModal
