import React, {useState} from "react"
import Spinner from "../spinner"
import {AccountInfo} from "../../types"
import {useUserPool} from "../../contexts/user-context"
import {useAlert} from "../../contexts/alert-context"
import ChangePasswordModal from "./change-password-modal"
import ChangeEmailModal from "./change-email-modal"
import VerifyEmailModal from "./verify-email-modal"
import {AccountService} from "../../services/account-service";

interface SignInSettingsProps {
  info: AccountInfo | null
}


interface SignInSettingsState {
  isChangePasswordModalVisible: boolean
  isChangeEmailModalVisible: boolean
  isVerifyEmailModalVisible: boolean
  isBusy: boolean
  newEmail: string
  currentEmail: string
}

function SignInSettings({info}: SignInSettingsProps) {
  const user = useUserPool()
  const alert = useAlert()

  const [state, setState] = useState<SignInSettingsState>({
    isChangeEmailModalVisible: false,
    isChangePasswordModalVisible: false,
    isVerifyEmailModalVisible: false,
    isBusy: false,
    newEmail: '',
    currentEmail: info !== null ? info.Email : "",
  })

  const handleChangePassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    alert.clear()
    setState({...state, isBusy: true})

    const err = await user.changePassword(oldPassword, newPassword)
    if (err != null) {
      alert.error(err.message)
      setState({...state, isBusy: false})
      return
    }

    setState({...state, isBusy: false, isChangePasswordModalVisible: false})
  }

  const handleChangeEmail = async (email: string) => {
    alert.clear()
    setState({...state, isBusy: true})

    const err = await user.changeEmail(email)
    if (err != null) {
      alert.error(err.message)
      setState({...state, isBusy: false})
      return
    }

    setState({
      ...state,
      isBusy: false,
      isChangeEmailModalVisible: false,
      isVerifyEmailModalVisible: true,
      newEmail: email,
    })
  }

  const handleVerifyEmailChange = async (code: string) => {
    alert.clear()
    setState({...state, isBusy: true})

    const err = await user.verifyEmailChange(code)
    if (err != null) {
      alert.error(err.message)
      setState({...state, isBusy: false})
      return
    }

    await AccountService.sync()

    setState({
      ...state,
      isBusy: false,
      isChangeEmailModalVisible: false,
      isVerifyEmailModalVisible: false,
      currentEmail: state.newEmail,
    })
  }

  const showChangePassword = () => {
    alert.clear()
    setState({...state, isChangePasswordModalVisible: true})
  }

  const showChangeEmail = () => {
    alert.clear()
    setState({...state, isChangeEmailModalVisible: true})
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-3 flex-column flex-sm-row align-items-sm-center">
        <div className="mb-2 mb-sm-0">
          <div className="mb-1 text-muted">Email address</div>
          <div className="">{info === null ? <Spinner/> : state.currentEmail}</div>
        </div>
        <button className="btn btn-primary" onClick={showChangeEmail}>
          Change Email
        </button>
      </div>
      <div className="d-flex justify-content-between flex-column flex-sm-row align-items-sm-center">
        <div className="mb-1 mb-sb-0">
          <div className="text-muted mb-1">Password</div>
          <div className="">{info === null ? <Spinner/> : "********"}</div>
        </div>
        <button
          className="btn btn-primary"
          onClick={showChangePassword}
        >
          Change Password
        </button>
      </div>
      <ChangePasswordModal
        show={state.isChangePasswordModalVisible}
        isBusy={state.isBusy}
        onHide={() => setState({...state, isChangePasswordModalVisible: false})}
        onSubmit={handleChangePassword}
      />
      <ChangeEmailModal show={state.isChangeEmailModalVisible} isBusy={state.isBusy}
                        onHide={() => setState({...state, isChangeEmailModalVisible: false})}
                        onSubmit={handleChangeEmail}/>
      <VerifyEmailModal show={state.isVerifyEmailModalVisible} isBusy={state.isBusy}
                        onHide={() => setState({...state, isVerifyEmailModalVisible: false})}
                        email={state.newEmail}
                        onSubmit={handleVerifyEmailChange}/>
    </>
  )
}

export default SignInSettings
