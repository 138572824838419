import React from "react"
import { Dropdown } from "react-bootstrap"
import "./domain-item.css"

interface DomainItemPlaceholderProps {
  className?: string
}

function DomainItemPlaceholder({ className }: DomainItemPlaceholderProps) {
  return (
    <div className={`col-12 col-sm-6 col-md-4 col-lg-3 mb-3 ${className}`}>
      <div className="card">
        <div className="card-body text-start">
          <div className="d-flex">
            <div className="placeholder-glow w-100">
              <span className="placeholder col-6" />
            </div>
            <Dropdown className="">
              <Dropdown.Toggle
                variant="vertical-ellipsis"
                className="opacity-50"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="ellipsis-v"
                  className=""
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 512"
                  height="24"
                  width="4"
                >
                  <path
                    fill="#777"
                    d="M32 224c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zM0 136c0 17.7 14.3 32 32 32s32-14.3 32-32-14.3-32-32-32-32 14.3-32 32zm0 240c0 17.7 14.3 32 32 32s32-14.3 32-32-14.3-32-32-32-32 14.3-32 32z"
                  />
                </svg>
              </Dropdown.Toggle>
            </Dropdown>
          </div>
          <div className="text-muted small">
            <div className="placeholder-glow">
              <div className="placeholder col-3 me-1" />
              <div className="placeholder col-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DomainItemPlaceholder
