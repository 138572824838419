import React, { FormEvent, useEffect, useState } from "react"
import { useUserPool } from "../contexts/user-context"
import Alert from "../components/alert"
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { useAlert } from "../contexts/alert-context"
import CenteredPage from "../components/centered-page"

interface LocationState {
  from: string
}

function SignInPage() {
  const alert = useAlert()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [isBusy, setBusy] = useState(false)
  const userPool = useUserPool()
  const navigate = useNavigate()
  const { verified } = useParams()
  const location = useLocation()
  const locationState = location.state as LocationState

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    alert.clear()
    setBusy(true)

    const error = await userPool.authenticateUser(email, password)
    if (error != null) {
      if (error.name === "UserNotConfirmedException") {
        navigate("/verify", { state: { email } })
        setBusy(false)
        return
      }
      alert.error(error.message)
      setBusy(false)
      return
    }

    if (locationState !== null && locationState.from) {
      navigate(locationState.from)
    } else {
      navigate("/")
    }
  }

  return (
    <CenteredPage>
      <form className="row justify-content-center" onSubmit={handleSubmit}>
        <div className="col-12 col-sm-7 col-md-5 col-xl-4">
          <h1 className="h3 mb-4 text-center">Sign In</h1>
          {verified ? (
            <div className="mb-4 alert alert-info">
              Your email address was successfully verified.
              <br /> You can now sign in into your account.
            </div>
          ) : null}
          <Alert />
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="email" className="text-muted">
              Email
            </label>
          </div>
          <div className="form-floating mb-1">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="password" className="text-muted">
              Password
            </label>
          </div>
          <div className="text-end mall mb-3">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
          <div className="mb-3">
            <button
              className="btn btn-primary w-100 btn-lg"
              disabled={isBusy ? true : undefined}
            >
              Sign In
            </button>
          </div>
          <div className="text-center">
            Don't have an account yet? <Link to="/register">Sign Up</Link>
          </div>
        </div>
      </form>
    </CenteredPage>
  )
}

export default SignInPage
