import React from "react"
import { DomainProvider } from "../contexts/domain-context"
import DomainList from "../components/domains/domain-list"
import DomainInput from "../components/domains/domain-input"
import Alert from "../components/alert"
import Page from "../components/page"
import DomainsHeader from "../components/domains/domains-header"

function DomainsPage() {
  return (
    <Page>
      <DomainProvider>
        <Alert />
        <DomainInput />
        <DomainsHeader />
        <DomainList />
      </DomainProvider>
    </Page>
  )
}

export default DomainsPage
