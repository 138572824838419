import {client} from "./client"
import {AxiosError, AxiosResponse} from "axios"
import {AccountInfo, AccountInfoSchema, DomainArraySchema, NotificationChannelMap} from "../types"

const responseBody = (response: AxiosResponse) => response.data
const catchError = (error: AxiosError) => {
  let message = "An unknown error has occurred."
  if (typeof error.response?.data === "string") {
    message = error.response?.data
  }
  throw new Error(message)
}

export const AccountService = {
  getInfo: async (): Promise<AccountInfo> => {
    const resp = await client.get("/account").then(responseBody)
    const result = AccountInfoSchema.safeParse(resp)

    if (result.success) {
      return result.data
    }

    throw new Error("Couldn't parse the response: getAccountInfo: " + result.error)
  },
  sync: (): Promise<void> =>
    client.get("/account/sync").then(responseBody),
  updateNotificationSettings: (settings: NotificationChannelMap): Promise<void> => client.post("/account/notificationSettings", settings).then(responseBody)
}
