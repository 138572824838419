import React, {useState} from "react"
import Page from "../components/page"
import {useLocation, useNavigate} from "react-router-dom";
import {StripeService} from "../services/stripe-service";

interface Plan {
  name: string
  desc: string
  monthlyPrice: number
  yearlyPrice: number
  features: PlanFeature[]
}

interface PlanFeature {
  name: string
  soon?: boolean
}

const lite: Plan = {
  name: "Lite",
  desc: "All our core features for those just starting out",
  yearlyPrice: 0,
  monthlyPrice: 0,
  features: [
    {name: '20 domains',},
    {name: 'Multi-channel notifications',},
    {name: 'Domain properties monitoring',},
    {name: '30-day activity history',}
  ],
}

const pro: Plan = {
  name: "Pro",
  desc: "Great for those with a large domain portfolio",
  yearlyPrice: 108,
  monthlyPrice: 14,
  features: [
    {name: '200 domains',},
    {name: 'Multi-channel notifications',},
    {name: 'Domain properties monitoring',},
    {name: '1 year activity history',},
    {name: 'Reputation monitoring', soon: true},
    {name: 'API Access', soon: true},
    {name: 'Priority support',},
  ],
}

interface PlanCardProps {
  plan: Plan,
  isYearly: boolean
}

function PlanCard({plan, isYearly}: PlanCardProps) {
  const navigate = useNavigate()
  const [isBusy, setBusy] = useState(false)

  const handleCheckout = async (price: string) => {
    setBusy(true)
    const url = await StripeService.createCheckoutSession(price)
    setBusy(false)
    window.location.href = url
  }

  return <div className="card p-4">
    <h5 className="display-6 text-center fw-bold">{plan.name}</h5>
    <p className="lead text-muted text-center">{plan.desc}</p>
    <div className="my-4">
      <div className="d-flex justify-content-center align-items-baseline">
        {isYearly
          ? <span className="me-2 display-5 fw-bold">${plan.yearlyPrice / 12}</span>
          : <span className="me-2 display-5 fw-bold">${plan.monthlyPrice}</span>}
        <span className="text-muted">{plan.monthlyPrice === 0 ? 'forever' : '/mo'}</span>
      </div>
      {!isYearly && plan.monthlyPrice > 0
        ? <div className="text-center small text-muted">billed monthly</div>
        : null}
      {isYearly && plan.yearlyPrice > 0
        ? <div className="text-center small text-muted">billed yearly at ${plan.yearlyPrice}</div>
        : null}
    </div>
    <ul className="list-unstyled mb-0 d-grid gap-2">
      {plan.features.map((f, i) => <li className="d-flex align-items-center" key={i}>
        <svg className="text-success me-1" width="20" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"></path>
        </svg>
        <span>{f.name}</span>
        {f.soon == true
          ? <span className="ms-2 small badge bg-secondary">soon</span>
          : null}
      </li>)}
    </ul>
    {plan.monthlyPrice === 0 ? (
      <div className="mt-4">
        <button className="btn btn-outline-secondary d-block w-100"
                onClick={() => navigate('/settings')}>{`Continue with ${plan.name} plan`}</button>
      </div>
    ) : null}
    {plan.monthlyPrice > 0 && !isYearly ? (
      <div className="mt-4">
        <button onClick={() => handleCheckout('monthly')}
                className={`btn btn-primary d-block w-100 ${isBusy ? 'disabled' : null}`}>
          {isBusy ? <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> : null}
          {`Upgrade to ${plan.name} monthly`}
        </button>
      </div>
    ) : null}
    {plan.monthlyPrice > 0 && isYearly ? (
      <div className="mt-4">
        <button onClick={() => handleCheckout('yearly')}
                className={`btn btn-primary d-block w-100 ${isBusy ? 'disabled' : null}`}>
          {isBusy ? <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> : null}
          {`Upgrade to ${plan.name} yearly`}
        </button>
      </div>
    ) : null}
  </div>
}

function PlanCardPlaceholder() {
  return <div className="card p-4">
    <div className="text-black-50">
      <div className="placeholder-glow w-100">
        <span className="placeholder col-4"/>
      </div>
    </div>
    <div className="placeholder-glow w-100">
      <span className="placeholder col-6"/>
    </div>
    <div className="text-muted">
      <div className="placeholder-glow">
        <div className="placeholder col-3 me-1"/>
        <div className="placeholder col-2"/>
      </div>
    </div>
  </div>
}

interface LocationState {
  usagePlan: string
}

function ChangePlanPage() {
  const locationState = useLocation().state as LocationState
  const [isYearly, setYearly] = useState(false)

  return <Page>
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8">
        <h4 className="mb-4 text-center">Change Plan</h4>
        <div className="d-flex align-items-center justify-content-center mb-3 fw-bold">
          <label onClick={() => setYearly(false)} role="button">Monthly</label>
          <button aria-checked={isYearly} role="switch"
                  className={`switch mx-2 ${isYearly ? 'active' : null}`} onClick={() => setYearly(!isYearly)}></button>
          <label onClick={() => setYearly(true)} role="button">Yearly</label>
          <span className="fw-normal ms-2">
          (save {Math.floor(100 - (pro.yearlyPrice / (pro.monthlyPrice * 12) * 100))}%)
          </span>
        </div>
        <div className="row justify-content-center">
          <div className="col-5">
            <PlanCard plan={lite} isYearly={isYearly} />
          </div>
          <div className="col-5">
            <PlanCard plan={pro} isYearly={isYearly} />
          </div>
        </div>
      </div>
    </div>
  </Page>
}

export default ChangePlanPage