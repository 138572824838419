import React, {useEffect, useState} from "react"

import Page from "../components/page"
import {useUserPool} from "../contexts/user-context";
import {useAlert} from "../contexts/alert-context";
import {AccountInfo, DomainLog} from "../types";
import {DomainService} from "../services/domain-service";
import {AccountService} from "../services/account-service";
import {useNavigate} from "react-router-dom";

function HistoryPage() {
  const user = useUserPool()
  const alert = useAlert()
  const [isLoaded, setLoaded] = useState(false)
  const [domainLog, setDomainLog] = useState<DomainLog[] | null>(null)
  const [usagePlan, setUsagePlan] = useState<string>('')
  const [activeRow, setActiveRow] = useState(-1)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user.isLoaded) {
      return
    }

    const fetchData = async () => {
      const accountInfo = await AccountService.getInfo()
      const data = await DomainService.getDomainLog()
      setUsagePlan(accountInfo.UsagePlan)
      setDomainLog(data)
      setLoaded(true)
    }

    fetchData().catch((e) => console.log(e.toString()))
  }, [user])


  return <Page>
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8">
        <h4 className="mb-4">Domain Change History</h4>

        {usagePlan == 'lite'
          ? <p>A list of changes made to your domain names in the last 30 days. <a href="#" onClick={() => navigate('/change-plan')}>Upgrade</a> to the Pro plan if you need a longer history.</p>
          : <p>A list of changes made to your domain names in the last 360 days.</p>}
        <div className="card-header d-none">
          <div className="row">
            <div className="col-lg-3">Date</div>
            <div className="col-lg-4">Domain</div>
            <div className="col-lg-4">Event</div>
          </div>
        </div>
          {!isLoaded && domainLog === null ? <>
            <div className="card mb-4">
            <ul className="list-group list-group-flush">
              <li className={`list-group-item domain-log-item`}>
                <div className="placeholder-glow w-100">
                  <span className="placeholder col-6"/>
                </div>
                <div className="text-muted">
                  <div className="placeholder-glow">
                    <div className="placeholder col-3 me-1"/>
                    <div className="placeholder col-2"/>
                  </div>
                </div>
              </li>
              <li className={`list-group-item domain-log-item`}>
                <div className="placeholder-glow w-100">
                  <span className="placeholder col-6"/>
                </div>
                <div className="text-muted">
                  <div className="placeholder-glow">
                    <div className="placeholder col-3 me-1"/>
                    <div className="placeholder col-2"/>
                  </div>
                </div>
              </li>
              <li className={`list-group-item domain-log-item`}>
                <div className="placeholder-glow w-100">
                  <span className="placeholder col-6"/>
                </div>
                <div className="text-muted">
                  <div className="placeholder-glow">
                    <div className="placeholder col-3 me-1"/>
                    <div className="placeholder col-2"/>
                  </div>
                </div>
              </li>
            </ul>
            </div>
          </> : null}
          {domainLog !== null && domainLog.length > 0 ?
            <div className="card mb-4">
            <ul className="list-group list-group-flush">
              {domainLog.map((entry, i) => (
                <li key={i} className={`list-group-item domain-log-item ${i === activeRow ? 'open' : ''}`}>
                  <div className="d-flex justify-content-between align-items-center" role="button"
                       onClick={() => setActiveRow(activeRow === i ? -1 : i)}>
                    <div className="row flex-grow-1 py-1">
                      <div
                        className="col-lg-3 text-black-50 py-0 d-flex align-items-center small">{entry.Created.toLocaleString()}</div>
                      <div className="col-lg-4 py-2 d-flex align-items-center fw-bold">{entry.Whois.Name}</div>
                      <div className="col-lg-4 mt-0 d-flex align-items-center">{entry.Title}</div>
                    </div>
                    <div role="button" onClick={() => setActiveRow(activeRow === i ? -1 : i)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="12" className="chevron">
                        <path
                          d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"/>
                      </svg>
                    </div>
                  </div>
                  <div className={`domain-log-item-details ${i === activeRow ? 'open' : ''}`}>
                    <hr className="mt-2"/>
                    {entry.Title === 'status changed' ? (
                      <div>
                        <div className="my-2">
                          <div className="fw-bold">Previous status:</div>
                          <div>{entry.PrevWhois?.Status?.join(", ")}</div>
                        </div>
                        <div className="my-2">
                          <div className="fw-bold">Current status:</div>
                          <div>{entry.Whois?.Status?.join(", ")}</div>
                        </div>
                      </div>
                    ) : null}
                    {entry.Title === 'nameserver changed' ? (
                      <div>
                        <div className="my-2">
                          <div className="fw-bold">Previous nameservers:</div>
                          <div>{entry.PrevWhois?.Nameservers?.join(", ")}</div>
                        </div>
                        <div className="my-2">
                          <div className="fw-bold">Current nameservers:</div>
                          <div>{entry.Whois?.Nameservers?.join(", ")}</div>
                        </div>
                      </div>
                    ) : null}
                    {entry.Title === 'expiration changed' ? (
                      <div>
                        <div className="my-2">
                          <div className="fw-bold">Previous expiration date:</div>
                          <div>{entry.PrevWhois?.Expiry?.toLocaleString()}</div>
                        </div>
                        <div className="my-2">
                          <div className="fw-bold">Current expiration date:</div>
                          <div>{entry.Whois?.Expiry?.toLocaleString()}</div>
                        </div>
                      </div>
                    ) : null}
                    {entry.Title === 'expires in 30 days' || entry.Title === 'expires in 7 days' || entry.Title === 'expires in 1 day' ? (
                      <div>
                        <div className="my-2">
                          <div className="fw-bold">Expiration date:</div>
                          <div>{entry.Whois?.Expiry?.toLocaleString()}</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </li>
              ))}

            </ul></div> : null}

      </div>
    </div>
  </Page>
}

export default HistoryPage
