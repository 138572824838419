import React, { useEffect, FormEvent, useState } from "react"
import { Modal } from "react-bootstrap"
import { DomainService } from "../../services/domain-service"
import { AxiosError } from "axios"
import { Domain, Labels } from "../../types"
import { useDomain } from "../../contexts/domain-context"
import Spinner from "../spinner"

interface LabelProps {
  name: string
  color: string
  onDelete: (name: string) => void
  onChange: (name: string, checked: boolean) => void
  defaultChecked: boolean
}

function Label({ name, onDelete, onChange, defaultChecked }: LabelProps) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={defaultChecked}
          onChange={(e) => onChange(name, e.target.checked)}
          value=""
          id={`label-${name}`}
        />
        <label className="form-check-label" htmlFor={`label-${name}`}>
          {name}
        </label>
      </div>
      <button className="btn btn-link p-0" onClick={() => onDelete(name)}>
        Delete
      </button>
    </div>
  )
}

interface LabelAsModalProps {
  domain: Domain
  show: boolean
  onHide: () => void
}

function LabelAsModal({ show, domain, onHide }: LabelAsModalProps) {
  const [newLabelValue, setNewLabelValue] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setLoading] = useState(true)
  const [isCreateLabelBusy, setCreateLabelBusy] = useState(false)
  const [labels, setLabels] = useState<Labels>({})
  const { attachLabel, removeLabel, removeLabels } = useDomain()

  useEffect(() => {
    if (!show) {
      return
    }

    DomainService.getLabels().then((labels) => {
      setLabels(labels)
      setLoading(false)
    })
  }, [show])

  if (!show) {
    return null
  }

  const handleCreateLabel = async (e: FormEvent) => {
    e.preventDefault()
    setCreateLabelBusy(true)
    setError("")

    try {
      await DomainService.createLabel(newLabelValue, "#ffffff")
      setLabels({ ...labels, [newLabelValue]: { Color: "#ffffff" } })
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(
          typeof err.response !== "undefined"
            ? `Error: ${err.response.data}`
            : err.message
        )
      }
    }
    setNewLabelValue("")
    setCreateLabelBusy(false)
  }

  const handleDelete = async (name: string) => {
    await DomainService.deleteLabel(name)
    const { [name]: label, ...newLabels } = labels
    removeLabels(name)
    setLabels(newLabels)
  }

  const handleChange = async (label: string, checked: boolean) => {
    if (checked) {
      attachLabel(domain.Name, label)
    } else {
      removeLabel(domain.Name, label)
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>Label as...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? <div className="alert alert-warning">{error}</div> : null}
        <form onSubmit={handleCreateLabel}>
          <label className="mb-2">Create label</label>
          <div className="row g-2">
            <div className="col">
              <input
                id="newLabel"
                type="text"
                className="form-control form-control-sm"
                value={newLabelValue}
                onChange={(e) => setNewLabelValue(e.target.value)}
                required
                disabled={isCreateLabelBusy ? true : undefined}
                placeholder="Label name"
              />
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary btn-sm"
                disabled={isCreateLabelBusy}
              >
                Create
              </button>
            </div>
          </div>
        </form>
        {isLoading ? (
          <div className="mt-3">
            <Spinner />
          </div>
        ) : null}
        {!isLoading && labels !== null && Object.keys(labels).length > 0 ? (
          <div className="mt-4">
            <label className="mb-2">Attach label</label>
            {Object.keys(labels).map((k) => (
              <Label
                key={k}
                name={k}
                color={labels[k].Color}
                onDelete={handleDelete}
                onChange={handleChange}
                defaultChecked={typeof domain.Labels[k] !== "undefined"}
              />
            ))}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary btn-sm" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default LabelAsModal
