import React from "react"
import { useDomain } from "../../contexts/domain-context"
import DomainItem from "./domain-item"
import DomainItemPlaceholder from "./domain-item-placeholder"
import { Domain, DomainGrouping } from "../../types"
import { TransitionGroup, CSSTransition } from "react-transition-group"

function GroupedByLabel({ domains }: { domains: Domain[] }) {
  const labels = Array.from(
    new Set(domains.flatMap((x) => Object.keys(x.Labels)).values())
  )

  const groupedDomains = labels.map((label) => {
    return {
      label: label,
      domains: domains.filter((d) => d.Labels.hasOwnProperty(label)),
    }
  })

  const unlabelledDomains = domains.filter(
    (d) => d.Labels === null || Object.keys(d.Labels).length === 0
  )

  return (
    <div>
      {groupedDomains.map((g) => (
        <div className="mb-3" key={g.label}>
          <h5 className="mb-3 fw-normal d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              height="16"
              fill="currentColor"
              className="me-2"
            >
              <path d="M625.941 293.823L421.823 497.941c-18.746 18.746-49.138 18.745-67.882 0l-1.775-1.775 22.627-22.627 1.775 1.775c6.253 6.253 16.384 6.243 22.627 0l204.118-204.118c6.238-6.239 6.238-16.389 0-22.627L391.431 36.686A15.895 15.895 0 0 0 380.117 32h-19.549l-32-32h51.549a48 48 0 0 1 33.941 14.059L625.94 225.941c18.746 18.745 18.746 49.137.001 67.882zM252.118 32H48c-8.822 0-16 7.178-16 16v204.118c0 4.274 1.664 8.292 4.686 11.314l211.882 211.882c6.253 6.253 16.384 6.243 22.627 0l204.118-204.118c6.238-6.239 6.238-16.389 0-22.627L263.431 36.686A15.895 15.895 0 0 0 252.118 32m0-32a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.746 18.746-49.138 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118V48C0 21.49 21.49 0 48 0h204.118zM144 124c-11.028 0-20 8.972-20 20s8.972 20 20 20 20-8.972 20-20-8.972-20-20-20m0-28c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.49-48 48-48z" />
            </svg>
            {g.label}
          </h5>
          <div className="row">
            {g.domains.map((d) => (
              <DomainItem key={d.Name} domain={d} />
            ))}
          </div>
        </div>
      ))}
      {unlabelledDomains.length > 0 ? (
        <div>
          {groupedDomains.length > 0 ? (
            <div>
              <p className="text-muted mt-4">Unlabelled domains</p>
            </div>
          ) : null}
          <div className="row">
            {unlabelledDomains.map((d) => (
              <DomainItem key={d.Name} domain={d} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

function GroupedByRegistrar({ domains }: { domains: Domain[] }) {
  const registrars = Array.from(new Set(domains.map((d) => d.Registrar)))

  const groupedDomains = registrars.map((registrar) => {
    return {
      registrar: registrar,
      domains: domains.filter((d) => d.Registrar === registrar),
    }
  })

  return (
    <div>
      {groupedDomains.map((g) => (
        <div className="mb-3" key={g.registrar}>
          <h5 className="mb-3 fw-normal d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              height="16"
              fill="currentColor"
              className="me-2"
            >
              <path d="M376 256c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm-40 24c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24zm176-128c0 12.296-4.629 23.507-12.232 32 7.603 8.493 12.232 19.704 12.232 32v80c0 12.296-4.629 23.507-12.232 32 7.603 8.493 12.232 19.704 12.232 32v80c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-80c0-12.296 4.629-23.507 12.232-32C4.629 319.507 0 308.296 0 296v-80c0-12.296 4.629-23.507 12.232-32C4.629 175.507 0 164.296 0 152V72c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v80zm-480 0c0 8.822 7.178 16 16 16h416c8.822 0 16-7.178 16-16V72c0-8.822-7.178-16-16-16H48c-8.822 0-16 7.178-16 16v80zm432 48H48c-8.822 0-16 7.178-16 16v80c0 8.822 7.178 16 16 16h416c8.822 0 16-7.178 16-16v-80c0-8.822-7.178-16-16-16zm16 160c0-8.822-7.178-16-16-16H48c-8.822 0-16 7.178-16 16v80c0 8.822 7.178 16 16 16h416c8.822 0 16-7.178 16-16v-80zm-80-224c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24zm-64 0c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24zm64 240c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24z" />
            </svg>
            {g.registrar}
          </h5>
          <div className="row">
            {g.domains.map((d) => (
              <DomainItem key={d.Name} domain={d} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

function DomainList() {
  const { state } = useDomain()
  const { grouping, domains } = state
  if (state.isLoading) {
    return (
      <div className="row">
        <DomainItemPlaceholder />
        <DomainItemPlaceholder />
        <DomainItemPlaceholder className="d-none d-xs-block" />
        <DomainItemPlaceholder className="d-none d-lg-block" />
        <DomainItemPlaceholder className="d-none d-lg-block" />
        <DomainItemPlaceholder className="d-none d-lg-block" />
      </div>
    )
  }

  if (domains.length === 0) {
    return <div>Your domain list is empty</div>
  }

  if (grouping === DomainGrouping.None) {
    return (
      <TransitionGroup className="row" exit={false}>
        {domains.map((d) => (
          <CSSTransition key={d.Name} timeout={3000} classNames="domain-item">
            <DomainItem domain={d} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    )
  }

  if (grouping === DomainGrouping.Label) {
    return <GroupedByLabel domains={domains} />
  }

  if (grouping === DomainGrouping.Registrar) {
    return <GroupedByRegistrar domains={domains} />
  }

  return null
}

export default DomainList
