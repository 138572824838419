import React, {FormEvent, useState} from "react"
import { Modal } from "react-bootstrap"
import Alert from "../alert"

interface ChangeEmailModalProps {
  show: boolean
  isBusy: boolean
  onHide: () => void
  onSubmit: (newEmail: string) => void
}

function ChangeEmailModal({show, isBusy, onHide, onSubmit}: ChangeEmailModalProps) {
  const [newEmail, setNewEmail] = useState("")

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    onSubmit(newEmail)
  }

  return <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Change Email</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Alert />
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control"
            id="newEmail"
            placeholder="email"
            onChange={(e) => setNewEmail(e.target.value)}
            disabled={isBusy ? true : undefined}
            required
          />
          <label htmlFor="newEmail" className="text-muted">
            New Email
          </label>
        </div>
        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-primary btn-lg">
            Change
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg"
            onClick={onHide}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
}

export default ChangeEmailModal
