import React, { useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { getDomains } from "../../services/db"

interface DomainsModalProps {
  username: string
  onHide: () => void
}

function DomainsModal({ username, onHide }: DomainsModalProps) {
  const [isLoading, setLoading] = useState(true)
  const [domains, setDomains] = useState<string[]>([])

  useEffect(() => {
    if (username === "") {
      setLoading(true)
      setDomains([])
      return
    }

    getDomains(username).then((d) => {
      setLoading(false)
      setDomains(d)
    })
  }, [username])

  if (username === "") {
    return null
  }

  return (
    <Modal show={username !== ""} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>Domains</Modal.Title>
      </Modal.Header>
      {isLoading ? (
        <Modal.Body>
          <Spinner animation="border" size="sm" />
        </Modal.Body>
      ) : null}
      {domains.length > 0 ? (
        <ul className="list-group list-group-flush">
          {domains.map((domain) => (
            <li key="domain" className="list-group-item">
              {domain}
            </li>
          ))}
        </ul>
      ) : (
        <Modal.Body>No domains</Modal.Body>
      )}
    </Modal>
  )
}

export default DomainsModal
