import React, { FormEvent, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useUserPool } from "../contexts/user-context"
import Alert from "../components/alert"
import { useAlert } from "../contexts/alert-context"
import CenteredPage from "../components/centered-page"

interface LocationState {
  email: string
}

function VerifyPage() {
  const up = useUserPool()
  const alert = useAlert()
  const [isBusy, setBusy] = useState(false)
  const [code, setCode] = useState("")
  const location = useLocation()
  const locationState = location.state as LocationState
  const navigate = useNavigate()
  const [isVerified, setVerified] = useState(false)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    alert.clear()
    setBusy(true)

    if (locationState === null || locationState.email === null) {
      alert.error(
        "Email address was lost between pages. Please register again."
      )
      setBusy(false)
    }

    const error = await up.verifyUser(locationState.email, code)
    if (error != null) {
      alert.error(error.message)
      setBusy(false)
      return
    }

    setBusy(false)
    navigate("/login/verified", { state: { verified: true } })
  }

  const handleRequestNewCode = async () => {
    const err = await up.resendVerificationCode(locationState.email)
    if (err != null) {
      alert.error(err.message)
      return
    }

    alert.success("A new verification code has been sent.")
  }

  if (isVerified) {
    return (
      <CenteredPage>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-7 col-md-5 col-xl-4">
            <h1 className="h3 mb-4 text-center">Verify Email</h1>
            <p className="text-center">
              Your email address was successfully verified.
              <br />
              You can now sign in into your account.
            </p>
            <div className="text-center">
              <button className="btn btn-primary" onClick={() => navigate("/")}>
                Go to the Sign In page
              </button>
            </div>
          </div>
        </div>
      </CenteredPage>
    )
  }

  return (
    <CenteredPage>
      <form className="row justify-content-center" onSubmit={handleSubmit}>
        <div className="col-12 col-sm-7 col-md-5 col-xl-5">
          <h3 className="mb-4 text-center">We need to verify your email</h3>
          <Alert />
          <p className="text-center mb-4">
            A verification code has been sent to{" "}
            <strong>{locationState.email}</strong>
            <br />
            Please check the spam folder if you can't find the code.
          </p>
          <div className="row justify-content-center">
            <div className="col-8">
              <div className="form-floating mb-1">
                <input
                  type="number"
                  className="form-control"
                  id="code"
                  placeholder="code"
                  onChange={(e) => setCode(e.target.value)}
                  disabled={isBusy ? true : undefined}
                  required
                />
                <label htmlFor="code" className="text-muted">
                  Code
                </label>
              </div>
              <div className="text-end mall mb-3">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => handleRequestNewCode()}
                >
                  Request a new code
                </button>
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  className="btn btn-primary w-100 btn-lg"
                  disabled={isBusy ? true : undefined}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </CenteredPage>
  )
}

export default VerifyPage
