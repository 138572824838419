import React, { FormEvent, useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Alert from "../alert"
import { StringMap } from "../../types"

interface DiscordSettingsModalProps {
  show: boolean
  isBusy: boolean
  onHide: () => void
  onSubmit: (settings: StringMap) => void
  settings: StringMap | undefined | null
}

function DiscordSettingsModal({
  settings,
  show,
  isBusy,
  onSubmit,
  onHide,
}: DiscordSettingsModalProps) {
  const initialUrl =
    settings !== null &&
    typeof settings !== "undefined" &&
    settings["Url"] !== null
      ? settings["Url"]
      : ""
  const [url, setUrl] = useState(initialUrl)
  useEffect(() => setUrl(initialUrl), [initialUrl])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await onSubmit({ ...settings, Url: url })
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Discord Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert />
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="url"
              placeholder="url"
              defaultValue={initialUrl}
              onChange={(e) => setUrl(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="oldPassword" className="text-muted">
              Webhook URL
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-primary btn-lg">
              Save
            </button>
            <button
              type="button"
              className="btn btn-light btn-lg"
              onClick={onHide}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default DiscordSettingsModal
