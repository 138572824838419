import {Dropdown} from "react-bootstrap"
import React from "react"
import {useDomain} from "../../contexts/domain-context"
import {DomainGrouping} from "../../types"

function Sort() {
  const {state, updateSort} = useDomain()
  const sort = state.sort
  return <Dropdown>
    <Dropdown.Toggle
      variant="light"
      className="mx-2 d-flex align-items-center"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="16" className="me-1" fill="currentColor">
        <path
          d="M164 384h-44V48a16 16 0 0 0-16-16H88a16 16 0 0 0-16 16v336H28a12 12 0 0 0-8.73 20.24l68 72a12 12 0 0 0 17.44 0l68-72A12 12 0 0 0 164 384zm200.72-276.24l-68-72a12 12 0 0 0-17.44 0l-68 72A12 12 0 0 0 220 128h44v336a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V128h44a12 12 0 0 0 8.72-20.24z"/>
      </svg>
      {sort === 0 ? "Expiration" : ""}
      {sort === 1 ? "Alphabetical" : ""}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item
        active={sort === 0}
        onClick={() => updateSort(0)}
      >
        Expiration
      </Dropdown.Item>
      <Dropdown.Item
        active={sort === 1}
        onClick={() => updateSort(1)}
      >
        Alphabetical
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
}

function Grouping() {
  const {state, updateGrouping} = useDomain()
  const grouping = state.grouping
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        className="mx-2 d-flex align-items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          height="18"
          className="me-2"
          fill="currentColor"
        >
          <path
            d="M404 192h-84v-52c0-6.6-5.4-12-12-12H108c-6.6 0-12 5.4-12 12v168c0 6.6 5.4 12 12 12h84v52c0 6.6 5.4 12 12 12h200c6.6 0 12-5.4 12-12V204c0-6.6-5.4-12-12-12zm-276 96V160h160v128zm256 64H224v-32h84c6.6 0 12-5.4 12-12v-84h64zm116-224c6.6 0 12-5.4 12-12V44c0-6.6-5.4-12-12-12h-72c-6.6 0-12 5.4-12 12v20H96V44c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44v72c0 6.6 5.4 12 12 12h20v256H12c-6.6 0-12 5.4-12 12v72c0 6.6 5.4 12 12 12h72c6.6 0 12-5.4 12-12v-20h320v20c0 6.6 5.4 12 12 12h72c6.6 0 12-5.4 12-12v-72c0-6.6-5.4-12-12-12h-20V128zM32 64h32v32H32zm32 384H32v-32h32zm352-52v20H96v-20c0-6.6-5.4-12-12-12H64V128h20c6.6 0 12-5.4 12-12V96h320v20c0 6.6 5.4 12 12 12h20v256h-20c-6.6 0-12 5.4-12 12zm64 52h-32v-32h32zM448 96V64h32v32z"/>
        </svg>
        Grouping
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          active={grouping === 0}
          onClick={() => updateGrouping(0)}
        >
          None
        </Dropdown.Item>
        <Dropdown.Item
          active={grouping === 1}
          onClick={() => updateGrouping(1)}
        >
          Label
        </Dropdown.Item>
        <Dropdown.Item
          active={grouping === 2}
          onClick={() => updateGrouping(2)}
        >
          Registrar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function Filters() {
  const {state, updateGrouping, updateSort} = useDomain()
  const grouping = state.grouping
  const sort = state.sort
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        className="ms-1 d-flex align-items-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="12" className="me-2" fill="currentColor">
          <path
            d="M272 352h-24V16c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v336h-24c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h24v80c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-80h24c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM112 96H88V16c0-8.8-7.2-16-16-16H56c-8.8 0-16 7.2-16 16v80H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h24v336c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V160h24c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm320 128h-24V16c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v208h-24c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h24v208c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V288h24c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"/>
        </svg>
        Filters
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-checkboxes">
        <Dropdown.Header>Group by</Dropdown.Header>
        <Dropdown.Item
          active={grouping === 0}
          onClick={() => updateGrouping(0)}
        >
          None
        </Dropdown.Item>
        <Dropdown.Item
          active={grouping === 1}
          onClick={() => updateGrouping(1)}
        >
          Label
        </Dropdown.Item>
        <Dropdown.Item
          active={grouping === 2}
          onClick={() => updateGrouping(2)}
        >
          Registrar
        </Dropdown.Item>
        <Dropdown.Divider/>
        <Dropdown.Header>Sort by</Dropdown.Header>
        <Dropdown.Item
          active={sort === 0}
          onClick={() => updateSort(0)}
        >
          Expiration
        </Dropdown.Item>
        <Dropdown.Item
          active={sort === 1}
          onClick={() => updateSort(1)}
        >
          Alphabetical
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function Customize() {
  const {state, updateColumns} = useDomain()
  const columns = state.columns
  return (
    <Dropdown align="end">
      <Dropdown.Toggle
        variant="light"
        className="ms-1 d-flex align-items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="16"
          className="me-0"
        >
          <path
            d="M482.696 299.276l-32.61-18.827a195.168 195.168 0 0 0 0-48.899l32.61-18.827c9.576-5.528 14.195-16.902 11.046-27.501-11.214-37.749-31.175-71.728-57.535-99.595-7.634-8.07-19.817-9.836-29.437-4.282l-32.562 18.798a194.125 194.125 0 0 0-42.339-24.48V38.049c0-11.13-7.652-20.804-18.484-23.367-37.644-8.909-77.118-8.91-114.77 0-10.831 2.563-18.484 12.236-18.484 23.367v37.614a194.101 194.101 0 0 0-42.339 24.48L105.23 81.345c-9.621-5.554-21.804-3.788-29.437 4.282-26.36 27.867-46.321 61.847-57.535 99.595-3.149 10.599 1.47 21.972 11.046 27.501l32.61 18.827a195.168 195.168 0 0 0 0 48.899l-32.61 18.827c-9.576 5.528-14.195 16.902-11.046 27.501 11.214 37.748 31.175 71.728 57.535 99.595 7.634 8.07 19.817 9.836 29.437 4.283l32.562-18.798a194.08 194.08 0 0 0 42.339 24.479v37.614c0 11.13 7.652 20.804 18.484 23.367 37.645 8.909 77.118 8.91 114.77 0 10.831-2.563 18.484-12.236 18.484-23.367v-37.614a194.138 194.138 0 0 0 42.339-24.479l32.562 18.798c9.62 5.554 21.803 3.788 29.437-4.283 26.36-27.867 46.321-61.847 57.535-99.595 3.149-10.599-1.47-21.972-11.046-27.501zm-65.479 100.461l-46.309-26.74c-26.988 23.071-36.559 28.876-71.039 41.059v53.479a217.145 217.145 0 0 1-87.738 0v-53.479c-33.621-11.879-43.355-17.395-71.039-41.059l-46.309 26.74c-19.71-22.09-34.689-47.989-43.929-75.958l46.329-26.74c-6.535-35.417-6.538-46.644 0-82.079l-46.329-26.74c9.24-27.969 24.22-53.869 43.929-75.969l46.309 26.76c27.377-23.434 37.063-29.065 71.039-41.069V44.464a216.79 216.79 0 0 1 87.738 0v53.479c33.978 12.005 43.665 17.637 71.039 41.069l46.309-26.76c19.709 22.099 34.689 47.999 43.929 75.969l-46.329 26.74c6.536 35.426 6.538 46.644 0 82.079l46.329 26.74c-9.24 27.968-24.219 53.868-43.929 75.957zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"/>
        </svg>
        &nbsp;
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-checkboxes">
        <Dropdown.Header>Columns</Dropdown.Header>
        <Dropdown.Item
          active={columns.daysLeft}
          onClick={() => updateColumns({...columns, daysLeft: !columns.daysLeft})}
        >
          Days Left
        </Dropdown.Item>
        <Dropdown.Item
          active={columns.registrar}
          onClick={() => updateColumns({...columns, registrar: !columns.registrar})}
        >
          Registrars
        </Dropdown.Item>
        <Dropdown.Item
          active={columns.labels}
          onClick={() => updateColumns({...columns, labels: !columns.labels})}
        >
          Labels
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function DomainsHeader() {
  const {state, updateGrouping} = useDomain()
  const grouping = state.grouping

  return (
    <div className="d-flex align-items-center justify-content-between mb-4 mt-5">
      <div className="d-flex align-items-center">
        <h4 className="mb-0">Domains</h4>
        <div className="d-none d-sm-block ms-3">
          {grouping !== DomainGrouping.None ? (
            <span className="py-1 px-2 d-block bg-white border rounded small d-flex align-items-center cursor-default">
              Grouped by&nbsp;
              <strong>
                {grouping === DomainGrouping.Label ? "label" : null}
                {grouping === DomainGrouping.Registrar ? "registrar" : null}
              </strong>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                width="9"
                className="ms-2 cursor-pointer"
                onClick={() => updateGrouping(DomainGrouping.None)}
              >
                <path
                  d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/>
              </svg>
            </span>
          ) : null}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Filters/>
        <Customize/>
      </div>
    </div>
  )
}

export default DomainsHeader
