import React, { useEffect, useState } from "react"
import { Dropdown, Spinner } from "react-bootstrap"
import { getUsers, User, indexes } from "../../services/db"
import DomainsModal from "./domains-modal"

function UserList() {
  const [isLoading, setLoading] = useState(true)
  const [users, setUsers] = useState<User[]>([])
  const [index, setIndex] = useState(indexes[0])
  const [domainModal, setDomainModal] = useState("")

  useEffect(() => {
    getUsers(index).then((result) => {
      setLoading(false)
      setUsers(result)
    })
  }, [])

  useEffect(() => {
    getUsers(index).then((result) => {
      setLoading(false)
      setUsers(result)
    })
  }, [index])

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h4 className="mb-0 d-flex align-items-center">
            Users{" "}
            {users.length > 0 ? (
              <div className="badge bg-success ms-2">{users.length}</div>
            ) : null}
          </h4>
          <Dropdown className="">
            <Dropdown.Toggle
              variant="light"
              size="sm"
              className="mx-2 d-flex align-items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="18"
                className="me-2"
              >
                <path d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h288c35.3 0 64-28.7 64-64v-32h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-52 288c0 17.6-14.4 32-32 32H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h288c17.6 0 32 14.4 32 32v384zM208 272c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm46.8 144c-19.5 0-24.4 7-46.8 7s-27.3-7-46.8-7c-21.2 0-41.8 9.4-53.8 27.4C100.2 326.1 96 339 96 352.9V392c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-39.1c0-7 2.1-13.8 6-19.6 5.6-8.3 15.8-13.2 27.3-13.2 12.4 0 20.8 7 46.8 7 25.9 0 34.3-7 46.8-7 11.5 0 21.7 5 27.3 13.2 3.9 5.8 6 12.6 6 19.6V392c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-39.1c0-13.9-4.2-26.8-11.4-37.5-12.3-18-32.9-27.4-54-27.4z" />
              </svg>
              Index: {index}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {indexes.map((idx) => (
                <Dropdown.Item key={idx} onClick={() => setIndex(idx)}>
                  {idx}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {isLoading ? (
          <Spinner animation="border" size="sm" className="mt-3" />
        ) : null}

        {users.length > 0 ? (
          <div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Domains</th>
                  <th>Last action</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.PK}>
                    <td>{user.Email}</td>
                    <td className="text-center">
                      <div
                        className="badge bg-primary text-dark"
                        onClick={() => setDomainModal(user.PK)}
                        role="button"
                      >
                        {user.Domains}
                      </div>
                    </td>
                    <td>{user.LastAction}</td>
                    <td>{user.Created}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <DomainsModal username={domainModal} onHide={() => setDomainModal("")} />
    </div>
  )
}

export default UserList
