import React, { FormEvent, useState } from "react"
import { useDomain } from "../../contexts/domain-context"
import { useAlert } from "../../contexts/alert-context"
import { Amplify } from "aws-amplify"
import {Link, useNavigate} from 'react-router-dom'

function DomainInput() {
  const alert = useAlert()
  const [value, setValue] = useState("")
  const [isBusy, setBusy] = useState(false)
  const { addDomain } = useDomain()
  const [showUpgrade, setShowUpgrade] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    alert.clear()
    if (!value) return
    const sanitizedValue = value
      .replace(/^https?:\/\//i, "")
      .replace(/\//g, "")
      .replace(/www\./, "")
    setValue(sanitizedValue)
    setBusy(true)

    try {
      await addDomain(sanitizedValue)
    } catch (err) {
      if (err instanceof Error) {
        if (err.message.indexOf("max number of") > -1) {
          setShowUpgrade(true)
        } else {
          alert.error(err.message)
        }
        setBusy(false)
        return
      }
    }

    setBusy(false)
    setValue("")
  }

  return (
    <div className="col col-md-8 col-lg-6 mx-auto">
      <form className="mb-4" onSubmit={handleSubmit}>
        {showUpgrade ? (
          <div className="alert alert-info alert-dismissible">
            <p>
              Max number of domain was reached. <br />Upgrade to the Pro plan if you need more.
            </p>
            <button className="btn btn-primary" onClick={() => navigate('/change-plan')}>Upgrade</button>
            <button type="button" className="btn-close" onClick={() => setShowUpgrade(false)} />
          </div>
        ) : null}
        <div className="d-flex">
          <div className="form-floating w-100">
            <input
              type="text"
              className="form-control form-control-lg"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              required
              disabled={isBusy ? true : undefined}
              placeholder="domain name"
            />
            <label className="mb-2 text-muted" htmlFor="domain">
              Add a domain
            </label>
          </div>
          <button
            type="submit"
            className="ms-3 px-4 btn btn-primary"
            disabled={isBusy}
          >
            Add
          </button>
        </div>
      </form>
    </div>
  )
}

export default DomainInput
