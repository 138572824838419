import React from "react"
import Header from "./header"
import Brand from "./brand"

function CenteredPage({ children }: { children: JSX.Element }) {
  return (
    <>
      <div className="container pb-5 h-100">
        <div className="d-flex flex-column h-100">
          <Header />
          <div className="flex-grow-1 d-flex flex-column justify-content-center">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default CenteredPage
