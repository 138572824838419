import {z} from "zod"

export const ContactSchema = z.object({
  Name: z.string(),
  Organization: z.string(),
  Email: z.string(),
  Phone: z.string(),
  Country: z.string(),
})

export type Contact = z.infer<typeof ContactSchema>

const LabelSchema = z.object({
  Color: z.string(),
})

export type Label = z.infer<typeof LabelSchema>


export const DomainSchema = z.object({
  Name: z.string(),
  RecordUpdated: z.string().datetime().transform(x => new Date(x)),
  Expiry: z.string().datetime().transform(x => new Date(x)),
  Created: z.string().datetime().transform(x => new Date(x)),
  Updated: z.string().datetime().transform(x => new Date(x)),
  Status: z.string().array().nullable(),
  Nameservers: z.string().array().nullable(),
  Registrar: z.string(),
  RegistrarUrl: z.string(),
  Labels: z.record(z.string(), LabelSchema),
  Registrant: ContactSchema.nullable(),
})

export const DomainArraySchema = DomainSchema.array()

export type DomainArray = z.infer<typeof DomainArraySchema>

export type Domain = z.infer<typeof DomainSchema>

const WhoisSchema = z.object({
  Name: z.string(),
  Expiry: z.string().datetime().transform(x => new Date(x)),
  Created: z.string().datetime().transform(x => new Date(x)),
  Updated: z.string().datetime().transform(x => new Date(x)),
  Status: z.string().array(),
  Nameservers: z.string().array().nullable(),
  Registrar: z.string(),
  RegistrarUrl: z.string(),
})

export type Whois = z.infer<typeof WhoisSchema>

const DomainLogSchema = z.object({
  Title: z.string(),
  Created: z.string().datetime().transform(x => new Date(x)),
  Whois: WhoisSchema,
  PrevWhois: WhoisSchema.nullable(),
})

export const DomainLogArraySchema = DomainLogSchema.array()

export type DomainLogArray = z.infer<typeof DomainLogArraySchema>

export type DomainLog = z.infer<typeof DomainLogSchema>

export enum NotificationChannelType {
  Email = "email",
  Discord = "discord",
  Slack = "slack",
  Telegram = "telegram",
}

const LabelsSchema = z.record(z.string(), LabelSchema)

export type Labels = z.infer<typeof LabelsSchema>

const StringMapSchema = z.record(z.string(), z.string())

export type StringMap = z.infer<typeof StringMapSchema>

const NotificationChannelSchema = z.object({
  Enabled: z.boolean(),
  Settings: StringMapSchema.nullable(),
})

export type NotificationChannel = z.infer<typeof NotificationChannelSchema>

const NotificationChannelMapSchema = z.record(z.string(), NotificationChannelSchema)

export type NotificationChannelMap = z.infer<typeof NotificationChannelMapSchema>

const SubscriptionSchema = z.object({
  Interval: z.string(),
  PeriodEnd: z.string().datetime().transform(x => new Date(x)),
  PeriodStart: z.string().datetime().transform(x => new Date(x)),
  Price: z.number(),
  CancelAt: z.string().datetime().nullable().transform(x => x != null ? new Date(x) : null),
})

export const AccountInfoSchema = z.object({
  Email: z.string(),
  UsagePlan: z.string(),
  Domains: z.number(),
  MaxDomains: z.number(),
  NotificationSettings: NotificationChannelMapSchema,
  Subscription: SubscriptionSchema.nullable(),
})

export type AccountInfo = z.infer<typeof AccountInfoSchema>

export enum DomainGrouping {
  None,
  Label,
  Registrar,
}

export enum DomainSort {
  Expiration,
  Alphabetical,
}

export interface DomainColumns {
  daysLeft: boolean
  registrar: boolean
  labels: boolean
}
