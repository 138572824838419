import {client} from "./client"
import {AxiosError, AxiosResponse} from "axios"
import {AccountInfo, NotificationChannelMap} from "../types"

const responseBody = (response: AxiosResponse) => response.data
const catchError = (error: AxiosError) => {
  let message = "An unknown error has occurred."
  if (typeof error.response?.data === "string") {
    message = error.response?.data
  }
  throw new Error(message)
}

export const StripeService = {
  createCheckoutSession: (price: string): Promise<string> =>
    client.get<string>(`/stripe/create-checkout-session?price=${price}`).then(responseBody),
  createPortalSession: (): Promise<string> =>
    client.get<string>('/stripe/create-portal-session').then(responseBody),
}
