import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import "./help-widget.css"
import { useUserPool } from "../contexts/user-context"
import { HelpService } from "../services/help-service"

interface LauncherProps {
  isActive: boolean
  onClick: () => void
}

function Launcher({ isActive, onClick }: LauncherProps) {
  return (
    <div
      className={`launcher bg-primary p-3 rounded rounded-circle ${
        isActive ? "active" : ""
      } shadow shadow-lg user-select-none`}
      onClick={onClick}
    >
      <svg
        width="14"
        className="icon-down text-white"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="currentColor"
          d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"
        />
      </svg>
      <svg
        width="28"
        className="icon-smile text-white"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zM320 133.2c14.8 0 26.8 12 26.8 26.8s-12 26.8-26.8 26.8-26.8-12-26.8-26.8 12-26.8 26.8-26.8zm-128 0c14.8 0 26.8 12 26.8 26.8s-12 26.8-26.8 26.8-26.8-12-26.8-26.8 12-26.8 26.8-26.8zm164.2 140.9C331.3 303.3 294.8 320 256 320c-38.8 0-75.3-16.7-100.2-45.9-5.8-6.7-5-16.8 1.8-22.5 6.7-5.7 16.8-5 22.5 1.8 18.8 22 46.5 34.6 75.8 34.6 29.4 0 57-12.6 75.8-34.7 5.8-6.7 15.9-7.5 22.6-1.8 6.8 5.8 7.6 15.9 1.9 22.6z"
        />
      </svg>
    </div>
  )
}

interface MessengerProps {
  isActive: boolean
  isBusy: boolean
  onSend: (email: string, message: string) => Promise<void>
}

function Messenger({ isActive, isBusy, onSend }: MessengerProps) {
  const user = useUserPool()
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await onSend(email, message)
    if (user.currentUser !== null) {
      setEmail(user.currentUser.email)
    } else {
      setEmail("")
    }
    setMessage("")
  }

  useEffect(() => {
    if (user.currentUser !== null) {
      setEmail(user.currentUser.email)
    } else {
      setEmail("")
    }
  }, [user])

  return (
    <div
      className={`messenger shadow shadow-lg card border rounded mb-3 ${
        isActive ? "active" : ""
      }`}
    >
      <div className={"card-header bg-dark text-white"}>
        Do you need any help?
      </div>
      <div className={"card-body"}>
        <p>
          Please, submit your request and we’ll get back to you as soon as
          possible.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="helpWidgetEmail" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="helpWidgetEmail"
              placeholder="name@example.com"
              onChange={(e) => setEmail(e.target.value)}
              disabled={isBusy ? true : undefined}
              value={email}
              //required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="helpWidgetMessage" className="form-label">
              Message
            </label>
            <textarea
              className="form-control"
              id="helpWidgetMessage"
              rows={6}
              disabled={isBusy}
              // required
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setMessage(e.target.value)
              }
              value={message}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Send
          </button>
        </form>
      </div>
    </div>
  )
}

function HelpWidget() {
  const [isBusy, setBusy] = useState(false)
  const [isActive, setActive] = useState(false)

  const handleSend = async (email: string, message: string) => {
    setBusy(true)
    await HelpService.Send(email, message)
    setBusy(false)
    setActive(false)
  }

  return (
    <div className="help-widget">
      <Messenger isActive={isActive} isBusy={isBusy} onSend={handleSend} />
      <Launcher isActive={isActive} onClick={() => setActive(!isActive)} />
    </div>
  )
}

export default HelpWidget
