import React from "react"
import Header from "./header"

function Page({ children }: { children: JSX.Element }) {
  return (
    <>
      <Header />
      <div className="container pb-5">{children}</div>
    </>
  )
}

export default Page
