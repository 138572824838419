import React, { FormEvent, useState } from "react"
import { useUserPool } from "../contexts/user-context"
import Alert from "../components/alert"
import { Link, useNavigate } from "react-router-dom"
import { useAlert } from "../contexts/alert-context"
import CenteredPage from "../components/centered-page"

function SignUpPage() {
  const alert = useAlert()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [isBusy, setBusy] = useState(false)
  const userPool = useUserPool()
  const navigate = useNavigate()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    alert.clear()
    setBusy(true)

    const error = await userPool.createUser(email, password)
    if (error != null) {
      alert.error(error.message)
      setBusy(false)
      return
    }

    navigate("/verify", { state: { email } })
  }

  return (
    <CenteredPage>
      <form className="row justify-content-center" onSubmit={handleSubmit}>
        <div className="col-12 col-sm-7 col-md-5 col-xl-4">
          <h1 className="h3 mb-4 text-center">Create Account</h1>
          <Alert />
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="email" className="text-muted">
              Email
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
              disabled={isBusy ? true : undefined}
              required
            />
            <label htmlFor="password" className="text-muted">
              Password
            </label>
          </div>
          <div className="mb-3">
            <button
              className="btn btn-primary w-100 btn-lg"
              disabled={isBusy ? true : undefined}
            >
              Create Account
            </button>
          </div>
          <div className="text-center">
            Already have an account? <Link to="/login">Sign In</Link>
          </div>
        </div>
      </form>
    </CenteredPage>
  )
}

export default SignUpPage
