import React, { useEffect, useState } from "react"
import RequireAuth from "../components/require-auth"
import { useUserPool } from "../contexts/user-context"
import { AccountService } from "../services/account-service"
import { useAlert } from "../contexts/alert-context"
import { AccountInfo, NotificationChannelMap } from "../types"
import Spinner from "../components/spinner"
import SignInSettings from "../components/settings/sign-in-settings"
import NotificationSettings from "../components/settings/notification-settings"
import Placeholder from "../components/placeholder"
import Page from "../components/page"
import {DomainService} from "../services/domain-service";
import {useNavigate, useSearchParams} from "react-router-dom";
import {StripeService} from "../services/stripe-service";

interface SettingsCardProps {
  title: string
  isLoading: boolean
  children: JSX.Element
}

function SettingsCard({ title, children, isLoading }: SettingsCardProps) {
  return (
    <div className={"card mb-4"}>
      <h5 className="card-header bg-white py-3">{title}</h5>
      <div className={"card-body"}>
        {isLoading ? <Placeholder /> : children}
      </div>
    </div>
  )
}

function SettingsPage() {
  const user = useUserPool()
  const alert = useAlert()
  const [info, setInfo] = useState<AccountInfo | null>(null)
  const [isLoaded, setLoaded] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isManageSubscriptionBusy, setManageSubscriptionBusy] = useState(false)

  useEffect(() => {
    if (!user.isLoaded) {
      return
    }

    const fetchData = async () => {
      const accountInfo = await AccountService.getInfo()
      setInfo(accountInfo)
      setLoaded(true)
    }

    fetchData().catch((e) => console.error(e.toString()))
  }, [user])

  const updateNotificationSettings = async (
    settings: NotificationChannelMap
  ) => {
    if (info === null) {
      return
    }

    await AccountService.updateNotificationSettings(settings)

    setInfo({ ...info, NotificationSettings: settings })
  }

  const handleManageSubscription = async () => {
    setManageSubscriptionBusy(true)
    const url = await StripeService.createPortalSession()
    window.location.href = url
  }

  return (
    <Page>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8">
          <h4 className="mb-4">Account Settings</h4>
          {searchParams.get('event') === 'checkout_canceled'
            ? <div className="alert alert-warning">The checkout session has been canceled.</div>
            : null}
          {searchParams.get('event') === 'checkout_success'
            ? <div className="text-center mt-0 mb-5">


              <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="32" viewBox="0 0 512 512"><path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z"/></svg>
              <h5 className="mb-3">Thank you for your order!</h5>

              <p>You successfully upgraded your account to the PRO plan.</p>

              <button className="btn btn-primary" onClick={() => navigate('/')}>
                Go to Domains
              </button>

            </div>
            : null}
          <SettingsCard title={"Usage Plan"} isLoading={info === null}>
            <>
              <div
                className={
                  "d-flex justify-content-between align-items-start mb-3"
                }
              >
                <div className="row gy-3 flex-grow-1">
                  <div className="col-12 col-md-3">
                    <div className="mb-1 text-muted">Plan name</div>
                    <div className="">
                      {info === null ? <Spinner /> : info?.UsagePlan}
                    </div>
                  </div>
                  {info?.Subscription !== null ? (
                  <div className="col-12 col-md-3">
                    <div className="mb-1 text-muted">Price</div>
                    <div className="">
                      {info === null ? <Spinner /> : (
                        <div>
                          ${(info?.Subscription?.Price/100).toFixed(2)}/{info?.Subscription?.Interval == "month" ? "month" : "year"}
                        </div>
                      )}
                    </div>
                  </div>) : null}
                  {info?.Subscription?.CancelAt === null ? (
                  <div className="col-12 col-md-6">
                    <div className="mb-1 text-muted">Next payment on</div>
                    <div className="">
                      {info === null ? <Spinner /> : (
                        <div>
                          {info?.Subscription.PeriodEnd.toLocaleString()}
                        </div>
                      )}
                    </div>
                  </div>) : null}
                  {info?.Subscription !== null && info?.Subscription?.CancelAt !== null ? (
                    <div className="col-12 col-md-6">
                      <div className="mb-1 text-muted">Subscription will end on</div>
                      <div className="">
                        {info === null ? <Spinner /> : (
                          <div>
                            {info?.Subscription?.CancelAt.toLocaleString()}
                          </div>
                        )}
                      </div>
                    </div>) : null}
                </div>
                <div className="ms-5">
                  <button className={`btn btn-primary ${info?.UsagePlan === 'pro' ? 'd-none' : null}`} onClick={() => navigate('/change-plan', {
                    state: {
                      usagePlan: info?.UsagePlan,
                      email: info?.Email,
                      username: user.currentUser?.username,
                    }
                  })}>
                    Upgrade
                  </button>

                  <button className={`btn btn-light border ${info?.UsagePlan !== 'pro' ? 'd-none' : null} ${isManageSubscriptionBusy ? 'disabled' : null}`} onClick={() => handleManageSubscription()}>
                    {isManageSubscriptionBusy ? <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> : null}
                    Manage Subscription
                  </button>
                </div>
              </div>

              <div>
                <div className="mb-1 text-muted">Domain quota</div>
                <div className="">
                  {info === null ? (
                    <Spinner />
                  ) : (
                    `${info.Domains} used of ${info.MaxDomains}`
                  )}
                </div>
              </div>
            </>
          </SettingsCard>
          <SettingsCard title={"Sign-in Method"} isLoading={info === null}>
            <SignInSettings info={info} />
          </SettingsCard>
          <SettingsCard title={"Notifications"} isLoading={info === null}>
            <NotificationSettings
              info={info}
              updateSettings={updateNotificationSettings}
            />
          </SettingsCard>
        </div>
      </div>
    </Page>
  )
}

export default SettingsPage
