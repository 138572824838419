import { Navigate, useLocation } from "react-router-dom"
import { useUserPool } from "../contexts/user-context"

function RequireAnon({ children }: { children: JSX.Element }) {
  const location = useLocation()
  const { currentUser } = useUserPool()

  if (currentUser.isLoaded && currentUser.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

export default RequireAnon
