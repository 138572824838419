import React from "react"
import {useAlert} from "../contexts/alert-context";

function Alert() {
  const alert = useAlert()

  if (alert.text === "") {
    return null
  }

  const text = alert.text.charAt(0).toUpperCase() + alert.text.slice(1)

  return (
    <div className={`mb-4 alert alert-${alert.type} ${alert.dismissible ? "alert-dismissible" : ""}`}>
      <div dangerouslySetInnerHTML={{__html: text}}></div>
      {alert.dismissible
        ? <button type="button" className="btn-close" onClick={() => alert.clear()} />
        : null}
    </div>
  )
}

export default Alert
