import { Navigate, useLocation } from "react-router-dom"
import { useUserPool } from "../contexts/user-context"

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation()
  const { currentUser } = useUserPool()

  if (currentUser.isLoaded && !currentUser.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />
  }

  return children
}

export default RequireAuth
