import { client } from "./client"
import { AxiosError, AxiosResponse } from "axios"
import {Domain, DomainArraySchema, DomainLogArraySchema, DomainLog, DomainArray, Label, Labels, DomainSchema} from "../types"

const responseBody = (response: AxiosResponse) => response.data
const catchError = (error: AxiosError) => {
  let message = "An unknown error has occurred."
  if (typeof error.response?.data === "string") {
    message = error.response?.data
  }
  throw new Error(message)
}

export const DomainService = {
  getDomains: async (): Promise<Domain[]> => {
    const resp = await client.get("/domains").then(responseBody)
    const result = DomainArraySchema.safeParse(resp)
    if (result.success) {
      return result.data
    }

    throw new Error("Couldn't parse the response: getDomains: " + result.error)
  },

  getDomainLog: async (): Promise<DomainLog[]> => {
    const resp = await client.get("/domains/log").then(responseBody)
    const result = DomainLogArraySchema.safeParse(resp)
    if (result.success) {
      return result.data
    }

    throw new Error("Couldn't parse the response: getDomains: " + result.error)
  },

  getDomain: (name: string): Promise<Domain> =>
    client.get<Domain>(`/domains/${name}`).then(responseBody),

  addDomain: async (name: string): Promise<Domain> => {
    const resp = await client.post(`/domains/${name}`).then(responseBody).catch(catchError)
    const result = DomainSchema.safeParse(resp)
    if (result.success) {
      return result.data
    }

    throw new Error(`AddDomain, couldn't parse the response: ${result.error}`)
  },

  updateDomain: async (name: string): Promise<Domain> => {
    const resp = await client
      .post(`/domains/${name}?action=refresh`)
      .then(responseBody)
      .catch(catchError)

    const result = DomainSchema.safeParse(resp)
    if (result.success) {
      return result.data
    }

    throw new Error("Couldn't parse the response: updateDomain")
  },

  deleteDomain: (name: string) =>
    client.delete(`/domains/${name}`).then(responseBody),

  getLabels: () => client.get<Labels>(`/domains/labels`).then(responseBody),

  createLabel: (name: string, color: string) =>
    client
      .post(`/domains/labels/${name}?color=${encodeURIComponent(color)}`)
      .then(responseBody),

  deleteLabel: (name: string) => client.delete(`/domains/labels/${name}`),

  attachLabel: (domain: string, label: string) =>
    client.post(`/domains/${domain}/labels/${label}`).then(responseBody),

  removeLabel: (domain: string, label: string) =>
    client.delete(`/domains/${domain}/labels/${label}`).then(responseBody),
}
