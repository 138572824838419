import React from "react"
import "./app.css"
import { Route, Routes } from "react-router-dom"
import SignUpPage from "./pages/sign-up-page"
import DomainsPage from "./pages/domains-page"
import HistoryPage from "./pages/history-page"
import VerifyPage from "./pages/verify-page"
import SignInPage from "./pages/sign-in-page"
import NotFoundPage from "./pages/not-found-page"
import SettingsPage from "./pages/settings-page"
import ForgotPassword from "./pages/forgot-password"
import HelpWidget from "./components/help-widget"
import RequireAuth from "./components/require-auth"
import RequireAnon from "./components/require-anon"
import AdminPage from "./pages/admin-page"
import {Helmet} from "react-helmet"
import ChangePlanPage from "./pages/change-plan-page";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <>
                <Helmet>
                  <title>Dashboard | Domain Watchman</title>
                </Helmet>
                <DomainsPage />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/history"
          element={
            <RequireAuth>
              <>
                <Helmet>
                  <title>History | Domain Watchman</title>
                </Helmet>
                <HistoryPage />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <>
                <Helmet>
                  <title>Settings | Domain Watchman</title>
                </Helmet>
                <SettingsPage />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/change-plan"
          element={
            <RequireAuth>
              <>
                <Helmet>
                  <title>Change Plan | Domain Watchman</title>
                </Helmet>
                <ChangePlanPage />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/register"
          element={
            <RequireAnon>
              <div className="container pb-5 h-100">
                <Helmet>
                  <title>Create Account | Domain Watchman</title>
                </Helmet>
                <SignUpPage />
              </div>
            </RequireAnon>
          }
        />
        <Route
          path="/verify"
          element={
            <RequireAnon>
              <div className="container pb-5 h-100">
                <Helmet>
                  <title>Verity Email | Domain Watchman</title>
                </Helmet>
                <VerifyPage />
              </div>
            </RequireAnon>
          }
        />
        <Route
          path="/login"
          element={
            <RequireAnon>
              <>
                <Helmet>
                  <title>Sign In | Domain Watchman</title>
                </Helmet>
                <SignInPage />
              </>
            </RequireAnon>
          }
        />
        <Route
          path="/login/:verified"
          element={
            <RequireAnon>
              <>
                <Helmet>
                  <title>Sign In | Domain Watchman</title>
                </Helmet>
                <SignInPage />
              </>
            </RequireAnon>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <RequireAnon>
              <div className="container pb-5 h-100">
                <Helmet>
                  <title>Forgot Password | Domain Watchman</title>
                </Helmet>
                <ForgotPassword />
              </div>
            </RequireAnon>
          }
        />
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <>
                <Helmet>
                  <title>Admin | Domain Watchman</title>
                </Helmet>
                <AdminPage />
              </>
            </RequireAuth>
          }
        />
        <Route path="*" element={
          <>
            <Helmet>
              <title>Page not Found | Domain Watchman</title>
            </Helmet>
            <NotFoundPage />
          </>
        } />
      </Routes>
      <HelpWidget />
    </div>
  )
}

export default App
