import {
  DynamoDBClient,
  ListTablesCommand,
  QueryCommand,
  QueryCommandInput,
  QueryCommandOutput,
} from "@aws-sdk/client-dynamodb"
import { Auth } from "aws-amplify"

const tableName = process.env.REACT_APP_TABLE_NAME || ""
const dbRegion = process.env.REACT_APP_DB_REGION || ""

async function getClient() {
  const credentials = await Auth.currentCredentials()

  const db = new DynamoDBClient({
    region: dbRegion,
    credentials: Auth.essentialCredentials(credentials),
  })

  return db
}

export interface User {
  PK: string
  Email: string
  Created: string
  LastAction: string
  Domains: string
}

export const indexes = ["GSI1PK-LastAction-index", "GSI1PK-Created-index"]

export async function getDomains(username: string) {
  const params: QueryCommandInput = {
    TableName: tableName,
    KeyConditionExpression: "PK = :pk and begins_with(SK, :sk)",
    ExpressionAttributeValues: {
      ":pk": { S: username },
      ":sk": { S: "DOMAIN" },
    },
  }

  const client = await getClient()
  const data: QueryCommandOutput = await client.send(new QueryCommand(params))

  if (data.Items === null || typeof data.Items === "undefined") {
    return []
  }

  return data.Items.map((x) => {
    return (x.SK.S || "").replace("DOMAIN#", "").toLowerCase()
  })
}

export async function getUsers(index: string) {
  const users: User[] = []

  const params: QueryCommandInput = {
    TableName: tableName,
    IndexName: index,
    KeyConditionExpression: "GSI1PK = :key",
    ExpressionAttributeValues: {
      ":key": { S: "USER" },
    },
    ScanIndexForward: false,
  }

  const client = await getClient()
  const data: QueryCommandOutput = await client.send(new QueryCommand(params))

  if (data.Items === null || typeof data.Items === "undefined") {
    return users
  }

  return data.Items.map((x) => {
    return {
      PK: x.PK.S || "",
      Email: x.Email.S || "",
      LastAction: x.LastAction?.S || "",
      Created: x.Created.S || "",
      Domains: x.Domains.N || "",
    }
  })
}
