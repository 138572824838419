import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./app"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { UserPoolProvider } from "./contexts/user-context"
import LogRocket from "logrocket"

import "./custom.scss"
import { AlertProvider } from "./contexts/alert-context"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AlertProvider>
        <UserPoolProvider>
          <App />
        </UserPoolProvider>
      </AlertProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (localStorage.getItem("logrocket_ignore") === null) {
  LogRocket.init("d4akyq/domain-watchman")
}
